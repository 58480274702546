import ex from "../styles/actividades.module.css"

export function Actividad({ title, route, link, target="_blank" }) {
    return (
        <>
            <a className={ ex.actividades_el__link } href={ link } target={ target }></a>
            <div className={ ex.actividades_el__title }>{ title }</div>
            <img src={ "/images/actividades/" + route +".png" } alt={ title } className={ ex.actividades_el__img }/>
        </>
    )
}