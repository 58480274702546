import { useEffect } from "react";
import { Link } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link";
import { NavbarButton, SidebarButton, SidebarSobrante } from "./NavigationButtons"
import "./../styles/global.css"

export function Navbar() {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/habitats" className="navbar_options__el">Hábitats</Link>
                    <Link to="/entradas" className="navbar_options__el">Entradas</Link>
                    <Link to="/educacion" className="navbar_options__el">Educación</Link>
                    <a href="https://tickets.granacuario.com/" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile">
                <Link to="#" className="content_mobile__el">TIENDA <span>(próximamente)</span></Link>
                <a href="https://tickets.granacuario.com/" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function Sidebar() {
    return (
        <div className="sidebar">
            <SidebarSobrante/>
            <div className="sidebar_container">
                <SidebarButton/>
                <div className="sidebar_buttons">
                    <Link to="#" className="sidebar_tienda">TIENDA <span>(próximamente)</span></Link>
                    <a href="https://tickets.granacuario.com/" target="_blank" className="sidebar_entradas">TICKETS</a>
                </div>
                <div className="sidebar_language">
                    <Link to="#" className="sidebar_language__el active">ESPAÑOL</Link>
                    <Link to="#" className="sidebar_language__el">ENGLISH</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/entradas" className="sidebar_con__t1">ENTRADAS</Link></div>
                <div className="sidebar_con">
                    <Link to="/habitats" className="sidebar_con__t1">HÁBITATS</Link>
                    <Link to="/habitats/tierra" className="sidebar_con__t2">Tierra</Link>
                    <Link to="/habitats/costa" className="sidebar_con__t2">Costa</Link>
                    <Link to="/habitats/mar" className="sidebar_con__t2">Mar</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/educacion" className="sidebar_con__t1">EDUCACIÓN</Link></div>
                <div className="sidebar_con solo"><Link to="/investigacion" className="sidebar_con__t1">INVESTIGACIÓN</Link></div>
                <div className="sidebar_con solo"><Link to="/actividades" className="sidebar_con__t1">ACTIVIDADES</Link></div>
                <div className="sidebar_con solo"><Link to="/grupos" className="sidebar_con__t1">GRUPOS</Link></div>
                {/*<div className="sidebar_con solo"><Link to="#" target="_blank" className="sidebar_con__t1">TIENDA EN LÍNEA</Link></div>*/}
                <div className="sidebar_con">
                    <Link to="/visita" className="sidebar_con__t1">PLANIFICA TU VISITA</Link>
                    <NavHashLink to="/visita#tarifas" className="sidebar_con__t2">Tarifas y horarios</NavHashLink>
                    <NavHashLink to="/visita#recomendaciones" className="sidebar_con__t2">Recomendaciones</NavHashLink>
                    <NavHashLink to="/visita#ruta" className="sidebar_con__t2">Como llegar</NavHashLink>
                    <NavHashLink to="/visita#plano" className="sidebar_con__t2">Plano del acuario</NavHashLink>
                </div>
                <div className="sidebar_con solo"><Link to="/faq" className="sidebar_con__t1">PREGUNTAS FRECUENTES</Link></div>
                <div className="sidebar_con solo"><Link to="/contacto" className="sidebar_con__t1">CONTACTO</Link></div>
            </div>
        </div>
    )
}

export function ExhibitionLink({ linkDes, path, classes="" }) {
    return ( <Link to={ path } class={ classes }>{ linkDes }</Link> )
}