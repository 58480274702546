import { Navbar, Sidebar } from "../components/Navigation"
import { useRef } from "react"
import { ResponsiveImage } from "../components/ResponsiveImage"
import Header from "../components/Header"
import contacto from "./../styles/contacto.module.css"
import cx from "classnames"
import Footer from "../components/Footer"

export default function Contacto() {
    const name = useRef(null);
    const email = useRef(null);
    const asunto = useRef(null);
    const msg = useRef(null);


    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName="contacto" altName="Render acuario" headerTitle="CONTACTO" titleClasses="default"/>

            {/* Contenido */}
            <div className={ cx(contacto.contenido, 'section') }>
                <div className={ cx(contacto.contenido_content, 'content')}>
                    <div className={ contacto.contenido_des }>
                        <div className={ contacto.contenido_des__title }>QUEREMOS ESCUCHARTE</div>
                        <div className={ contacto.contenido_des__des }>¡Bienvenido a nuestra sección de contacto! Nos encanta que estés interesado en visitarnos y queremos asegurarte que serás muy bien recibido. Estamos ansiosos por conocerte y mostrarte lo sorprendente del Mar de Cortés.</div>
                        <div className={ contacto.contenido_des__des }>Nuestra dirección es <a href="https://goo.gl/maps/ULHfEH2LtHDNXQcTA" target="_blank">Av. de los Deportes 111, Tellería, 82017 Mazatlán, Sinaloa.</a></div>
                        <div className={ contacto.contenido_des__des }>Estamos ubicados en el corazón del Parque Central Mazatlán  y estamos abiertos de lunes a domingo todo el año.</div>
                        <div className={ contacto.contenido_des__des }>En caso de que necesites ponerte en contacto con nosotros, no dudes en hacerlo a través de nuestro correo electrónico <a href="mailto:contacto@granacuario.com">contacto@granacuario.com</a>. Si prefieres visitarnos en persona, nuestro horario de atención al público es de 10:00 am a 6:00 pm.</div>
                        <div className={ contacto.contenido_des__des }>Te aseguramos que haremos todo lo posible para que tu experiencia con nosotros sea la mejor posible. ¡Esperamos verte pronto!</div>
                    </div>
                    <div className={ contacto.contacto_links }>
                        <div className={ contacto.contacto_links_title }>CONTACTO DIRECTO</div>
                        <a href="mailto:contacto@granacuario.com" className={ contacto.contacto_links_el }>Envíanos un correo a contacto@granacuario.com</a>
                        <a href="https://goo.gl/maps/ULHfEH2LtHDNXQcTA" target="_blank" className={ contacto.contacto_links_el }>Visítanos en Av. de los Deportes 111, Tellería</a>
                        <a href="https://wa.me/+5216691390134?text=%C2%A1Hola%21+Tengo+una+duda+sobre+el+Gran+Acuario+Mazatl%C3%A1n" target="_blank" className={ contacto.contacto_links_el }>Envíanos un whatsapp</a>
                        <div className={ contacto.contacto_links_title }>NUESTRAS REDES SOCIALES</div>
                        <div className={ contacto.contacto_links_social }>
                            <a href="https://www.facebook.com/granacuariomazatlan" target="_blank" className={ contacto.contacto_links_social_el }><img src="/images/footer/fb_grey.svg" alt="Facebook Icon"/></a>
                            <a href="https://www.instagram.com/granacuariomazatlan/" target="_blank" className={ contacto.contacto_links_social_el }><img src="/images/footer/ins_grey.svg" alt="Instagram Icon"/></a>
                            <a href="https://twitter.com/GranAcuarioMzt" target="_blank" className={ contacto.contacto_links_social_el }><img src="/images/footer/tw_grey.svg" alt="Twitter Icon"/></a>
                        </div>
                    </div>
                    {/*
                    <div className={ contacto.contenido_form }>
                        <form>
                            <div className={ contacto.contenido_des__title }>Contacto</div>
                            <input className={ contacto.form_input } ref={ name } type="text" name="name" id="name" placeholder="Nombre" required/>
                            <input className={ contacto.form_input } ref={ email } type="email" name="email" id="email" placeholder="Correo" required/>
                            <input className={ contacto.form_input } ref={ asunto } type="text" name="asunto" id="asunto" placeholder="Asunto" required/>
                            <textarea className={ contacto.form_input } ref={ msg } name="mensaje" id="mensaje" placeholder="Mensaje" rows="5" required/>
                            <input className={ cx(contacto.form_input, contacto.form_send ) } type="submit" value="Enviar"/>
                        </form>
                    </div>
                    */}
                </div>
            </div>

            {/* Mapa */}
            <div className={ cx(contacto.mapa, 'section') }>
                <ResponsiveImage route="contacto/mapa" alt="Mapa acuario" />
                <div className={ contacto.mapa_des }>Abierto todo el año, de lunes a domingo.</div>
            </div>

            <Footer/>
        </>
    )
}