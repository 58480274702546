import faq from './../styles/faq.module.css'
import cx from 'classnames'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion"

export function Pregunta({ pregunta, respuesta }) {
    return(
        <div className={ faq.pregunta }>
            <div className={ cx(faq.pregunta_pregunta) }>{ pregunta }</div>
            <div className={ faq.pregunta_respuesta }>{ respuesta }</div>
        </div>
    )
}

export function PreguntaAccordion({ pregunta, respuesta }) {
    return (
        <AccordionItem className={ faq.preguntas_item }>
            <AccordionItemHeading className={ faq.preguntas_item__title }>
                <AccordionItemButton>{ pregunta }</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={ faq.preguntas_item__respuesta }>{ respuesta }</AccordionItemPanel>
        </AccordionItem>
    )
}