import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { habitats } from "../array/habitats"
import Regresar from "../components/Regresar"
import ex from "../styles/exhibicionEspecie.module.css"
import cx from "classnames"

export default function Especie() {
    const { habitat, especie }  = useParams();

    const habitatInfo = habitats.find(el => el.id == habitat);
    const especieInfo = habitatInfo.especies.find(el => el.route == especie);

    const headerName = especieInfo.nombre_display ? especieInfo.nombre_display : especieInfo.nombre_comun;

    console.log(habitatInfo);
    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName={ habitatInfo.habitat + "/" + habitat } titleClasses={ habitatInfo.habitat } altName="Hábitat header image" headerTitle={ headerName }/>

            <div className={ ex.contenido + " section" }>
                <div className={ ex.content + " content" }>
                    {/* Nombres y estatus de conservación */}
                    <div className={ cx(ex.contenido_des, ex.des) }>
                        <div className={ cx(ex.contenido__t1, ex.cientifico) }>{ especieInfo.nombre_cientifico }</div>
                        <div className={ ex.contenido__t2 }>{ especieInfo.nombre_comun }</div>
                        <div className={ ex.contenido__t1 }>Estatus de conservación</div>
                        <div className={ ex.contenido__t2 }>{ especieInfo.conservacion }.<br/>Categorías y criterios de la lista roja de la UICN:</div>
                        <img src="/images/habitats/estatus.svg" alt="Estatus de conservación" className={ ex.contenido__estatus }/>
                    </div>
                    {/* Imágenes */}
                    <div className={ ex.contenido_images }>
                        <img src={ "/images/" + habitatInfo.habitat + "/" + habitat + "/" + especie + "/a.png" } srcSet={ "/images/" + habitatInfo.habitat + "/" + habitat + "/" + especie + "/a.webp" } alt={ especieInfo.nombre_comun } className={ ex.contenido_images__el }/>
                        <img src={ "/images/" + habitatInfo.habitat + "/" + habitat + "/" + especie + "/b.png" } srcSet={ "/images/" + habitatInfo.habitat + "/" + habitat + "/" + especie + "/b.webp" } alt={ especieInfo.nombre_comun } className={ ex.contenido_images__el }/>
                    </div>
                    {/* Datos curiosos y generalidades */}
                    <div className={ cx(ex.contenido_des, ex.des) }>
                        <div className={ ex.contenido__t1 }>Datos curiosos</div>
                        { especieInfo.datos != undefined &&
                            especieInfo.datos.map((dato, index) =>
                                <div key={ index } className={ ex.contenido__t2 }>{ dato }</div>
                            )
                        }
                        <div className={ ex.contenido__t1 }>Generalidades</div>
                        { especieInfo.generalidades != undefined &&
                            especieInfo.generalidades.map((generalidad, index) =>
                                <div key={ index } className={ ex.contenido__t2 }>{ generalidad }</div>)
                        }
                    </div>
                </div>

                <Regresar link={ "/habitats/" + habitatInfo.habitat + "/" + habitat } des={ habitatInfo.nombre.charAt(0).toUpperCase() + habitatInfo.nombre.replace("/", " ").slice(1).toLocaleLowerCase() }/>
            </div>

            <Footer/>
        </>
    )
}