export function ResponsiveImage({ route, alt, classes }) {
    return (
        <>
            <picture>
                <source type="image/webp" srcSet={ "/images/" + route + "@1920.webp" } media="(min-width:1400px)"/>
                <source type="image/png" srcSet={ "/images/" + route + "@1920.png" } media="(min-width:1400px)"/>
                <source type="image/webp" srcSet={ "/images/" + route +"@1400.webp" } media="(max-width:1399px) and (min-width: 1200px)"/>
                <source type="image/png" srcSet={ "/images/" + route +"@1400.png" } media="(max-width:1399px) and (min-width: 1200px)"/>
                <source type="image/webp" srcSet={ "/images/" + route +"@1200.webp" } media="(max-width:1199px) and (min-width: 900px)"/>
                <source type="image/png" srcSet={ "/images/" + route +"@1200.png" } media="(max-width:1199px) and (min-width: 900px)"/>
                <source type="image/webp" srcSet={ "/images/" + route +"@900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
                <source type="image/png" srcSet={ "/images/" + route +"@900.png" } media="(max-width:899px) and (min-width: 600px)"/>
                <img src={ "/images/" + route +"@600.png" } srcSet={ "/images/" + route +"@600.webp" } alt={ alt } className={ classes }/>
            </picture>
        </>
    )
}

export function ResponsiveImage600({ route, alt, classes }) {
    return (
        <>
            <picture>
                <source type="image/webp" srcSet={ "/images/" + route + ".webp"  }media="(min-width:600px)"/>
                <source type="image/png" srcSet={ "/images/" + route + ".png"  }media="(min-width:600px)"/>
                <img src={ "/images/" + route + "@500.png" } srcSet={ "/images/" + route + "@500.webp" } alt={ alt } className={ classes }/>
            </picture>
        </>
    )
}