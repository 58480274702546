'use client'
import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import { Pregunta, Especie } from "../components/HabitatElements"
import Footer from "../components/Footer"
import { useParams } from "react-router-dom"
import ex from "../styles/exhibicionTipo.module.css"
import cx from "classnames"
import { Link } from "react-router-dom"
import { habitats } from "../array/habitats"

export default function Habitat() {

    const { habitat }  = useParams();
    const actual = habitats.find(element => element.id == habitat);

    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName={ actual.habitat + "/" + actual.id } titleClasses={ actual.habitat } altName="Hábitat header image" headerTitle={ actual.nombre }/>

            {/* Descripción */}
            <div className="section">
                <div className="content">
                    <div className={ cx(ex.habitat__t1, ex.des) }>{ actual.description }</div>

                    {/* Especies */}
                    <div className={ ex.habitat_con }>
                        {   actual.especies != undefined &&
                                actual.especies.map(el => <Especie route={ el.route } exhibicion={ actual.habitat } habitat={ actual.id } nombre={ el.nombre_comun.replace('/', ' ') } nombreDisplay={ el.nombre_display } key={ el.route }/>) }
                    </div>

                    {/* Preguntas */}
                    <div className={ ex.preguntas }>
                        {   actual.preguntas != undefined &&
                                actual.preguntas.map((el, index) => <Pregunta pregunta={el.pregunta} respuesta={el.respuesta} key={index}/>) }
                    </div>
                </div>
                <div className="contenido_back"><Link to={ "/habitats/" + actual.habitat }></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>{ actual.habitat.charAt(0).toUpperCase() + actual.habitat.slice(1) }</div>
            </div>

            <Footer/>
        </>
    )
}