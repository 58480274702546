import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import { Actividad } from "../components/Actividad"
import Footer from "../components/Footer"

import { Link } from "react-router-dom"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react"

import ex from "../styles/actividades.module.css"
import cx from "classnames"

gsap.registerPlugin(ScrollTrigger);

export default function Actividades() {
    {/* Entradas en bloque */}
    const bloque1 = useRef(null);
    const bloque2 = useRef(null);
    const bloque3 = useRef(null);
    const bloque4 = useRef(null);
    const bloque5 = useRef(null);
    const bloque6 = useRef(null);
    const bloque7 = useRef(null);
    const bloque8 = useRef(null);

    useEffect(() => {
        gsap.from(bloque1.current, { duration: 1.5, opacity: 0, scrollTrigger: '.bloque1' });
    }, []);
    useEffect(() => {
        gsap.from(bloque2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque2' });
    }, []);
    useEffect(() => {
        gsap.from(bloque3.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque3' });
    }, []);
    useEffect(() => {
        gsap.from(bloque4.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque4' });
    }, []);
    useEffect(() => {
        gsap.from(bloque5.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque5' });
    }, []);
    useEffect(() => {
        gsap.from(bloque6.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque6' });
    }, []);
    useEffect(() => {
        gsap.from(bloque7.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque7' });
    }, []);
    useEffect(() => {
        gsap.from(bloque8.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.bloque8' });
    }, []);

    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName="actividades" altName="Pecera redonda"/>

            {/* Actividades */}
            <div className={ ex.actividades + " section" }>
                {/* Título */}
                <div className={ cx(ex.titulo, 'bloque1') } ref={ bloque1 }>
                    <div className={ ex.titulo__t1 + " title" }>Actividades</div>
                    <div className={ ex.titulo__sep }></div>
                    <div className={ ex.titulo__t2 }>Grandiosas actividades para disfrutar en el Gran Acuario, para toda la familia.</div>
                </div>
                {/* Elementos */}
                <div className={ ex.actividades_con }>
                    <div ref={ bloque1 } className={ cx(ex.actividades_el, 'bloque1') }><Actividad title="Recorrido Calypso" route="recorrido_calipso" link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                    <div ref={ bloque2 } className={ cx(ex.actividades_el, 'bloque2') }><Actividad title="Nado con tiburones" route="nado_tiburones" link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                    <div ref={ bloque3 } className={ cx(ex.actividades_el, 'bloque3') }><Actividad title="Alimentación de rayas" route="alimentacion_rayas" link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                    <div ref={ bloque4 } className={ cx(ex.actividades_el, ex.half, 'bloque4') }><Actividad title="Alimentación de tiburones" route="alimentacion_tiburones" link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                    <div ref={ bloque5 } className={ cx(ex.actividades_el, 'bloque5') }><Actividad title="Dormir con tiburones" route="dormir_tiburones" link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                    <div ref={ bloque6 } className={ cx(ex.actividades_el, ex.half, 'bloque6') }><Actividad title="Eventos sociales" route="eventos_sociales" link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                    <div ref={ bloque7 } className={ cx(ex.actividades_el, 'bloque7') }>
                        <Link className={ ex.actividades_el__link } to="/actividades/fiestas"></Link>
                        <div className={ ex.actividades_el__title }>Fiestas de cumpleaños</div>
                        <img src="/images/actividades/fiestas_cumpleanos.png" alt="Fiestas de cumpleaños" className={ ex.actividades_el__img }/>
                    </div>
                    <div ref={ bloque8 } className={ cx(ex.actividades_el, ex.half, 'bloque8') }><Actividad title="Visitas escolares" route="visitas_escolares" classes={ ex.half } link="https://tickets.granacuario.com/mx/982-experiencias-premium"/></div>
                </div>

                <div className={ ex.contenido_back + " contenido_back"}><a href="https://tickets.granacuario.com/" target="_blank"></a><img src="/images/header/entradas.svg" alt="Tickets"/>Comprar tickets</div>
            </div>
            
            <Footer/>
        </>
    )
}