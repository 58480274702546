import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import Footer from "../components/Footer"

import { Espacio } from "../components/Sociales"

import s from "../styles/sociales.module.css"
import cx from "classnames"

export default function Fiestas() {
    return (
        <>
            <Navbar/>
            <Sidebar/>

            <Header routeName="sociales" altName="Mesa de fiesta servida" headerTitle="EVENTOS SOCIALES" titleClasses="default"/>

            {/* Títulos */}
            <div className={ s.titulos }>
                <img className={ s.titulos_bg } src="/images/sociales/title_bg.png" srcset="/images/sociales/title_bg.webp"/>
                <div className={ cx(s.titulos_content, 'content') }>
                    <div className={ s.titulos_con }>
                        <div className={ cx(s.titulos_con_title, 'title') }>GRANDES EVENTOS, GRANDES RECUERDOS, SÓLO EN GRAN ACUARIO MAZATLÁN.</div>
                    </div>
                    <div className={ s.titulos_con }>
                        <div className={ s.titulos_con__t1 }>¿Cómo reservar?</div>
                        <div className={ s.titulos_con__t2 }>Si quieres festejar tu evento en el Gran Acuario Mazatlán, envíanos un correo con la información y el salón que te interesa para cotizar.</div>
                        <a href="mailto:eventos@granacuario.com" className={ s.titulos_con__link }>Cotizar</a>
                    </div>
                    <div></div>
                </div>
            </div>

            {/* Espacios de renta */}
            <div className={ cx(s.espacios, 'section') }>
                <Espacio imgRoute="gran_jardin" nombre="Gran Jardín" tipo="ESPACIO AL AIRE LIBRE" px="250 A 1200" hrs="5"/>
                <Espacio imgRoute="terraza_cormoranes" nombre="Terraza Cormoranes" tipo="ESPACIO AL AIRE LIBRE" px="70" hrs="5"/>
                <Espacio imgRoute="roof_garden" nombre="Roof Garden" tipo="ESPACIO AL AIRE LIBRE" px="150" hrs="5"/>
                <Espacio imgRoute="salon_laguna" nombre="Salón Laguna" tipo="ESPACIO A/C" px="150" hrs="5"/>
                <Espacio imgRoute="patio_central" nombre="Patio Central" tipo="ESPACIO AL AIRE LIBRE" px="100" hrs="5"/>
                <Espacio imgRoute="terraza_laguna" nombre="Terraza Laguna" tipo="ESPACIO AL AIRE LIBRE" px="100" hrs="5"/>
                {/*
                <div className={ s.espacios_contacto }>
                    <div className={ s.espacios_contacto__t1 }>¿Cómo reservar?</div>
                    <div className={ s.espacios_contacto__t2 }>Si quieres festejar tu evento en el Gran Acuario Mazatlán, envíanos un correo con la información y el salón que te interesa para cotizar.</div>
                    <a href="mailto:contacto@granacuario.com" className={ s.espacios_contacto__link }>Cotizar</a>
                </div>
                */}
            </div>

            <Footer/>
        </>
    )
}