import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";
gsap.registerPlugin(ScrollTrigger);

export default function Header({ routeName, altName="Default alt", headerTitle="notitle", classes="", titleClasses=""}) {

    const header_title_ref = useRef(null);
    useEffect(() => {
        gsap.to(header_title_ref.current, { duration: 1.5, transform: 'translateX(0)', ease: 'circ.out' });
    }, []);

    function header_title() {
        if(headerTitle != "notitle") {
            const split = headerTitle.search("/");
            const titleParts = headerTitle.split("/");
            const headerTitleClasses = titleClasses == "" ? "header_title " + routeName : "header_title " + titleClasses;
            const headerTitleBg = titleClasses == "" ? "/images/" + routeName + "/tramado.svg" : "/images/" + titleClasses + "/tramado.svg";

            return (
                <>
                { split < 0 && 
                    <div className={ headerTitleClasses } ref={ header_title_ref }>
                        <div className="header_title__t1 title">{ headerTitle }</div>
                        <img src={ headerTitleBg } alt="Tramado" className="header_title__bg"/>
                    </div>
                }
                { split >= 0 &&
                    <div className={ headerTitleClasses + " doble"} ref={ header_title_ref }>
                        <div className={ titleClasses + " header_title_doble"}>
                            <div className="header_title_doble__t1 title">{ titleParts[0] }</div>
                            <div className="header_title_doble__t2 title">{ titleParts[1] }</div>
                        </div>
                        <img src={ headerTitleBg } alt="Tramado" className="header_title__bg"/>
                    </div>
                }
                </>
            )
        }
    }

    return (
        <div className={"header " + classes }>
            <div className="header__shadow"></div>
            <picture>
                <source type="image/webp" srcSet={"/images/" + routeName + "/hero@1920.webp"} media="(min-width:1400px)"/>
                <source type="image/png" srcSet={"/images/" + routeName + "/hero@1920.png"} media="(min-width:1400px)"/>
                <source type="image/webp" srcSet={"/images/" + routeName + "/hero@1400.webp"} media="(max-width:1399px) and (min-width: 1200px)"/>
                <source type="image/png" srcSet={"/images/" + routeName + "/hero@1400.png"} media="(max-width:1399px) and (min-width: 1200px)"/>
                <source type="image/webp" srcSet={"/images/" + routeName + "/hero@1200.webp"} media="(max-width:1199px) and (min-width: 900px)"/>
                <source type="image/png" srcSet={"/images/" + routeName + "/hero@1200.png"} media="(max-width:1199px) and (min-width: 900px)"/>
                <source type="image/webp" srcSet={"/images/" + routeName + "/hero@900.webp"} media="(max-width:899px) and (min-width: 600px)"/>
                <source type="image/png" srcSet={"/images/" + routeName + "/hero@900.png"} media="(max-width:899px) and (min-width: 600px)"/>
                <img src={"/images/" + routeName + "/hero@600.png"} srcSet={"/images/" + routeName + "/hero@600.webp"} alt={ altName } className="header__bg"/>
            </picture>
            { header_title() }
        </div>
    )
}