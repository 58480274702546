import { Navbar, Sidebar } from "../components/Navigation"
import { Link } from "react-router-dom"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import educacion from "./../styles/educacion.module.css"
import cx from "classnames"
import Footer from "../components/Footer"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export default function Educacion() {

    const tortuga = useRef(null);
    const timeline_tortuga = useRef();
    useEffect(() => {
        timeline_tortuga.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.talleres_tortuga',
                start: '60% bottom',
                end: '+=120 0',
                scrub: true,
            },
        });
        timeline_tortuga.current.to(tortuga.current, {x: 70, y:-120, duration: 3,});
    });

    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName="educacion" altName="Tortuga" headerTitle="EDUCACIÓN" titleClasses="default"/>

            {/* Talleres */}
            <div className={ cx(educacion.talleres, 'section')}>
                <div className={ cx(educacion.talleres_content, 'content') }>
                    <div className={ educacion.talleres_title }>
                        <div className={ 'title' }>FOMENTAMOS EL CUIDADO DE LOS OCÉANOS DESPERTANDO EL INTERÉS DE LA SOCIEDAD A TRAVÉS DE UNA TRAVESÍA INIGUALABLE POR LOS ECOSISTEMAS DEL MAR DE CORTÉS.</div>
                    </div>
                    <div className={ educacion.talleres_des }>
                        <div className={ educacion.talleres_des__t1 }>Los alumnos tendrán la oportunidad de admirar los hábitats de más de 350 especies y aprender en un maravilloso recorrido, partiendo de costas y hasta el mar profundo, conociendo tiburones, caballitos de mar, tortugas, medusas, corales y mucho más.</div>
                        <div className={ educacion.talleres_des__t1 }>Experimenta este maravilloso mundo a través de 19 salas ambientadas en espacios abiertos y cerrados con instalaciones de clase mundial.</div>
                        <div className={ cx(educacion.talleres_des__title, 'title') }>TALLERES PARA INVESTIGADORES</div>
                        <div className={ educacion.talleres_des__t1 }>Conoce nuestro programa anual de talleres impartidos por doctores en biología y ciencias ambientales.</div>
                        <img className={ educacion.talleres_des__img } src="/images/educacion/tiburon.png" alt="Tiburón "/>
                        <a href="https://ciomc.org/#programas" target="_blank" className={ educacion.talleres_des__btn } to="/">Ver programas</a>
                    </div>
                </div>
                <Regresar link="/" des="Inicio" />
                <img ref={ tortuga } className={ educacion.talleres_tortuga } src="/images/educacion/tortuga.png" alt="Tortuga" />
            </div>

            <Footer/>
        </>
    )
}