import React from "react";
import ReactDOM from "react-dom/client";

import { createBrowserRouter, RouterProvider, createHashRouter } from "react-router-dom";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import Exhibiciones from "./pages/exhibiciones";
import Home from "./pages/home";
import Costa from "./pages/costa";
import Tierra from "./pages/tierra";
import Mar from "./pages/mar";
import Habitat from "./pages/habitat";
import Especie from "./pages/especie";
import Actividades from "./pages/actividades";
import Fiestas from "./pages/fiestas";
import Entradas from "./pages/entradas";
import Educacion from "./pages/educacion";
import Investigacion from "./pages/investigacion";
import Contacto from "./pages/contacto";
import Visita from "./pages/visita";
import Grupos from "./pages/grupos";
import Faq from "./pages/faq";

import "./styles/global.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="/entradas" element={ <Entradas/> }/>
                <Route path="/habitats" element={ <Exhibiciones/> }/>
                <Route path="/exhibiciones" element={ <Exhibiciones/> }/>
                <Route path="/educacion" element={ <Educacion/> }/>
                <Route path="/investigacion" element={ <Investigacion/> }/>
                <Route path="/actividades" element={ <Actividades/> }/>
                <Route path="/actividades/fiestas" element={ <Fiestas/> }/>
                <Route path="/grupos" element={ <Grupos/> }/>
                <Route path="/visita" element={ <Visita/> }/>
                <Route path="/contacto" element={ <Contacto/> }/>
                <Route path="/faq" element={ <Faq/> }/>
                {/* Costa paths */}
                <Route path="/habitats/costa" element={ <Costa/> }/>
                <Route path="/exhibiciones/costa.html" element={ <Costa/> }/>
                <Route path="/habitats/costa/:habitat" element={ <Habitat/> }/>
                <Route path="/exhibiciones/costa.html/:habitat" element={ <Habitat/> }/>
                <Route path="/habitats/costa/:habitat/:especie" element={ <Especie/> }/>
                <Route path="/exhibiciones/costa.html/:habitat/:especie" element={ <Especie/> }/>
                {/* Tierra paths */}
                <Route path="/habitats/tierra" element={ <Tierra/> }/>
                <Route path="/exhibiciones/tierra.html" element={ <Tierra/> }/>
                <Route path="/habitats/tierra/:habitat" element={ <Habitat/> }/>
                <Route path="/exhibiciones/tierra.html/:habitat" element={ <Habitat/> }/>
                <Route path="/habitats/tierra/:habitat/:especie" element={ <Especie/> }/>
                <Route path="/exhibiciones/tierra.html/:habitat/:especie" element={ <Especie/> }/>
                {/* Mar paths */}
                <Route path="/habitats/mar" element={ <Mar/> }/>
                <Route path="/exhibiciones/mar.html" element={ <Mar/> }/>
                <Route path="/habitats/mar/:habitat" element={ <Habitat/> }/>
                <Route path="/exhibiciones/mar.html/:habitat" element={ <Habitat/> }/>
                <Route path="/habitats/mar/:habitat/:especie" element={ <Especie/> }/>
                <Route path="/exhibiciones/mar.html/:habitat/:especie" element={ <Especie/> }/>
                {/* Error 404 */}
                <Route path="*" element={ <div>Página no encontrada</div> }/>
            </Routes>
        </BrowserRouter>
    </>
);