import { Navbar, Sidebar } from "../components/Navigation"
import { Link } from "react-router-dom"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import investigacion from "./../styles/investigacion.module.css"
import cx from "classnames"
import Footer from "../components/Footer"

export default function Investigacion() {
    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName="investigacion" altName="Tortugas" headerTitle="INVESTIGACIÓN/Y CONSERVACIÓN" titleClasses="default"/>

            {/* Centro de Investigación */}
            <div className={ cx(investigacion.ciomc, 'section') }>
                <div className={ investigacion.ciomc_content }>
                    <div className={ investigacion.ciomc_des }>
                        <div className={ investigacion.ciomc_des__t1 }>Centro de Investigaciones Oceánicas del Mar de Cortés -CIOMC-</div>
                        <div className={ investigacion.ciomc_des__title }>Líneas de investigación</div>
                        <div className={ investigacion.ciomc_des__t2 }>El CIO es un centro de investigación dedicado a identificar soluciones en beneficio de los océanos y del Mar de Cortés, en conjunto con el Gran Acuario de Mazatlán, se enfoca en investigaciones de alto nivel conectando la sociedad y el entorno natural.</div>
                        <div className={ investigacion.ciomc_des__sep }></div>
                        <div className={ investigacion.ciomc_des__title }>Laboratorio</div>
                        <div className={ investigacion.ciomc_des__t2 }>En tu paso por el laboratorio del Centro de Investigaciones Oceánicas conoce más de los hábitats, algunos de los alimentos que se proporcionan y los equipos que permiten optimas condiciones para las especies.</div>
                    </div>
                    <div className={ investigacion.ciomc_links }>
                        <div className={ investigacion.ciomc_links__el }>
                            <Link className={ investigacion.ciomc_links__el_link } to="#" ></Link>
                            <div className={ investigacion.ciomc_links__el_title }>Becas Mazatlán<img src="/images/investigacion/chevron.png" alt="Chevron"/></div>
                            <div className={ investigacion.ciomc_links__el_prox }>(Próximamente)</div>
                        </div>
                        <div className={ investigacion.ciomc_links__el }>
                            <Link className={ investigacion.ciomc_links__el_link } to="#" ></Link>
                            <div className={ investigacion.ciomc_links__el_title }>Fondo para la Conservación del Mar de Cortés<img src="/images/investigacion/chevron.png" alt="Chevron"/></div>
                            <div className={ investigacion.ciomc_links__el_prox }>(Próximamente)</div>
                        </div>
                    </div>
                </div>
                <Regresar link="/" des="Inicio" />
            </div>

            <Footer/>
        </>
    )
}