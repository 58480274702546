/*
    'preguntas': [
        {'pregunta': 'Pregunta', 'respuesta': 'Respuesta'},
        {'pregunta': 'Pregunta', 'respuesta': 'Respuesta'},
        {'pregunta': 'Pregunta', 'respuesta': 'Respuesta'},
    ],
    'especies': [
        {
            'nombre_comun':'',
            'nombre_display':'',
            'nombre_cientifico':'',
            'conservacion':'',
            'route':'',
            'datos': [
                '',
            ],
            'generalidades': [
                '',
            ],
        },
    ]
*/

/* HÁBITATS DE TIERRA */
export const TierraHabitats = [
    {"link": "terrario", "nombre": "TERRARIO"},
    {"link": "jardines", "nombre": "JARDINES"},
    /*{"link": "aviario", "nombre": "AVIARIO"},*/
];

/* HÁBITATS DE COSTA */
export const CostaHabitats = [
    {"link": "intermareal", "nombre": "INTERMAREAL"},
    {"link": "manglar", "nombre": "MANGLAR"},
    {"link": "tortugas", "nombre": "TORTUGAS"},
    {"link": "bahia-rayas", "nombre": "BAHÍA DE LAS RAYAS"},
    {"link": "rompiente", "nombre": "ROMPIENTE"},
]

/* HÁBITATS DE MAR */
export const MarHabitats = [
    {"link": "punto-pulmo", "nombre": "PUNTO PULMO"},
    {"link": "ciudad-coral", "nombre": "CIUDAD CORAL"},
    {"link": "establo-marino", "nombre": "ESTABLO MARINO"},
    {"link": "espejos", "nombre": "ESPEJOS"},
    {"link": "oceanica", "nombre": "OCEÁNICA"},
    {"link": "migracion", "nombre": "MIGRACIÓN"},
    {"link": "la-cueva", "nombre": "LA CUEVA"},
    {"link": "salon-de-las-medusas", "nombre": "SALÓN DE LAS MEDUSAS"},
    {"link": "cardumen", "nombre": "CARDÚMEN"},
    {"link": "la-ventana", "nombre": "LA VENTANA"},
];

export const habitats = [
    /* COSTA */
    { 
        /* INTERMAREAL */
        'id':'intermareal',
        'nombre':'INTERMAREAL',
        'habitat':'costa',
        'description':'Sumerge tus manos en un mundo de encuentro entre tierra y mar. Conoce a los integrantes del hábitat intermareal e interactúa con ellos ¡Descubre sus increíbles formas y texturas!',
        'preguntas': [
            {'pregunta':'¿Qué es la zona intermareal?', 'respuesta':'Es la zona de transición entre el ambiente terrestre y el mundo marino. Es una zona de gran dinamismo, que en cuestión de horas puede estar inundada o expuesta al aire.'},
            {'pregunta':'¿Quiénes viven en la zona intermareal?', 'respuesta':'Animales adaptados a ambientes cambiantes. Entre éstos se encuentran invertebrados como caracoles, cangrejos, estrellas de mar, erizos, anémonas y peces pequeños.'},
            {'pregunta':'¿Cuál es la importancia ecológica de estos lugares?', 'respuesta':'Proporciona alimento abundante a sus habitantes permanentes y temporales, como peces que llegan con la dinámica de las mareas y algunas aves. Además, son sitios de crianza para tortugas marinas, cangrejos y peces.'},
        ],
        'especies': [
            {
                'nombre_comun':'Borracho mono',
                'nombre_cientifico':'Ophioblennius steindachneri',
                'conservacion':'Preocupación menor',
                'route':'borracho-mono',
                'datos': [
                    'Le gusta vivir en las rocas, en la rompiente de las olas.',
                    'Son muy territoriales, defienden vigorosamente su hábitat de los intrusos.',
                    'Estos peces no tienen escamas.'
                ],
                'generalidades': [
                    'Cuerpo cilíndrico, cabeza con mechones (cirros) sobre sus ojos, nuca y en el borde de las fosas nasales.',
                    'Aletas inferiores alargadas.',
                    'Tiene los dientes incisivos en forma de peine para alimentarse de algas y de crustáceos.'
                ],
            },
            {
                'nombre_comun':'Estrella de mar chispas de chocolate',
                'nombre_display':'Estrella de mar/chispas de chocolate',
                'nombre_cientifico':'Nidorellia armata',
                'conservacion':'No evaluado',
                'route':'estrella',
                'datos': [
                    'La encontraron  como parte de la ofrenda en el Templo Mayor de Tenochtitlán (Cerca de la Ciudad de México).',
                    'El camarón  estrella de mar (Zenopontonia soror) suele asociarse a estrellas de mar como la chispas de chocolate: vive debajo ella, adopta el mismo color para camuflarse, le sirve como protección y se alimenta del moco que produce la estrella.',
                ],
                'generalidades': [
                    'Disco grueso e inflado de brazos cortos y gruesos, tiene espinas cónicas de color café oscuro que le dan su nombre común. Llega a medir hasta 15 cm de diámetro. Se alimenta de algas, otros equinodermos (erizos, pepinos) y pequeños invertebrados.',
                ],
            },
            {
                'nombre_comun':'Erizo punta de lápiz',
                'nombre_cientifico':'Eucidaris tribuloides',
                'conservacion':'No evaluado',
                'route':'erizo',
                'datos': [
                    'Cuentan con una compleja estructura para comer, la cual ha sido nombrada "Linterna de Aristóteles" en honor al sabio griego que la describió en su libro Historia animallum.',
                ],
                'generalidades': [
                    'El erizo punta de lápiz es un equinodermo: un animal sin huesos y de "piel espinosa", presenta exoesqueleto (esqueleto externo). Es de color café claro o rojizo y tiene pocas espinas gruesas, cafés y cilíndricas.',
                ],
            },
            {
                'nombre_comun':'Liebre marina',
                'nombre_cientifico':'Aplysia californica',
                'conservacion':'No evaluado',
                'route':'liebre-marina',
                'datos': [
                    'Se le llama liebre de mar porque tiene dos tentáculos detrás de los ojos que se parecen las orejas de una liebre.',
                    'Puede liberar una tinta color púrpura intenso y con olor a cedro cuando se siente amenazada.'
                ],
                'generalidades': [
                    'Molusco de color variable, de café rojizo o verdoso, textura carnosa y delicada. En la cabeza tiene cuatro tentáculos y en el dorso dos pliegues en forma de alas y que cubren sus branquias. En la parte inferior del cuerpo tiene un pie musculoso que usa para moverse.',
                    'Los juveniles viven a unos 18m de la superficie, mientras que los adultos prefieren la zona intermareal.',
                    'Se alimenta de algas rojas, lechuga de mar y plantas marinas.'
                ],
            },
            {
                'nombre_comun':'Labrisómido Bocón, chalapo o trambollo',
                'nombre_display':'Labrisómido Bocón',
                'nombre_cientifico':'Labrisomus xanti',
                'conservacion':'Preocupación menor',
                'route':'labrisomido',
                'datos': [
                    'Debido al aumento de la temperatura del agua por el calentamiento global, está cambiando su distribución y ha  comenzado a aparecer hacia el norte, hasta California, en Estados Unidos.',
                    'Los peces labrisómidos como el bocón son pequeños y flexibles para moverse entre las rocas y encontrar refugio en conchas y grietas.',
                ],
                'generalidades': [
                    'Es de color café verdoso con barras ocuras a los lados, tiene una mancha negra en la aleta dorsal y manchitas blancas en cabeza y cuerpo. Sobre cada ojo y en la nuca tiene penachos (cirro) ramificados. Boca grande.',
                    'Vive en aguas someras y en rocas cubiertas por algas.',
                    'Se alimenta de crustáceos del suelo como pequeños cangrejos.',
                ],
            },
            {
                'nombre_comun':'Borrachito o blenio de sonora',
                'nombre_display':'Blenio de sonora',
                'nombre_cientifico':'Malacoctenus gigas',
                'conservacion':'Preocupación menor',
                'route':'blenio',
                'datos': [
                    'Sólo se encuentra en las costas del Golfo de California, es decir, que es endémico de la zona.',
                    'Tolera temperaturas de hasta 10°C.',
                ],
                'generalidades': [
                    'Cabeza delgada, hocico puntiagudo y boca pequeña. Dorso color verde, pecho y vientre blancos, cuatro o cinco barras  de color café oscuro a los lados. Tienen un par de mechones (cirros) en la nuca y sobre los ojos.',
                    'En épocas de reproducción los machos tienen aletas oscuras, costados  anaranjado brillante con manchas azules azules.',
                    'Se alimenta de crustáceos pequeños.',
                    'Habita en aguas poco profundas, en arrecifes de coral y rocoso, entre algas marinas.',
                ],
            },
        ]
    },
    { 
        /* MANGLAR */
        'id':'manglar',
        'nombre':'MANGLAR',
        'habitat':'costa',
        'description':'Asómate a las raíces de nuestros manglares vivos. En el bosque anfibio habitan peces, crustáceos y muchos otros animales. El manglar nos cobija a todos y aquí te explicamos por qué.',
        'preguntas': [
            {'pregunta':'¿Qué son los manglares?', 'respuesta':'Son bosques anfibios de las zonas costeras tropicales, que crecen en áreas inundadas por las mareas. Están formados principalmente por árboles de mangle.'},
            {'pregunta':'¿Por qué son importantes los manglares?', 'respuesta':'En ellos interactúan distintas especies terrestres y acuáticas. Son sitios de reproducción y refugio de muchos animales en sus primeras etapas de vida. Además, proveen de nutrientes a ecosistemas marinos, filtran el agua y amortiguan el impacto de fenómenos meteorológicos como los huracanes.'},
            {'pregunta':'¿Qué especies de manglares podemos encontrar en el Mar de Cortés?', 'respuesta':'Mangle rojo (Rhizophora mangle), mangle blanco (Laguncularia racemosa), mangle prieto (Avicennia germinans) y Botoncillo (Conocarpus erectus).'},
        ],
        'especies': [
            {
                'nombre_comun':'Mangle negro o prieto, cenizo, puyeque, apompó, madre de sal',
                'nombre_display':"Mangle negro",
                'nombre_cientifico':'Avicennia germinans',
                'conservacion':'Preocupación menor',
                'route':'negro',
                'datos': [
                    'Es el mangle más tolerante al agua de mar.',
                    'Suele tener sus hojas cubiertas por polvo o cristales de sal porque tienen unas glándulas que eliminan el exceso de sal del agua que absorbe.'
                ],
                'generalidades': [
                    'A pesar de su nombre, es de color claro, tiene hojas opuestas de color gris pálido, brillante en la parte superior. Tiene unas raíces arqueadas que quedan expuestas cuando baja la marea y que emplean para respirar.',
                    'Sus flores son blancas o amarillas y florecen especialmente en época de lluvias.',
                    'Puede estar en aguas sin oxígeno, en zonas lodosas e inundadas.'
                ]
            },
            {
                'nombre_comun':'Mangle blanco',
                'nombre_cientifico':'Laguncularia racemosa',
                'conservacion':'Preocupación menor',
                'route':'blanco',
                'datos': [
                    'A veces es vivíparo, esto quiere decir que sus semillas germinan dentro del fruto mientras está aún en el árbol.',
                    'Su corteza tiene sustancias llamadas taninos que se emplean para curtir pieles.',
                    'Se ha usado como remedio tradicional para la disentería y problemas de salud por sus propiedades astringentes y tónicas.'
                ],
                'generalidades': [
                    'Arbusto de corteza fisurada, rugosa, grisácea-café. Sus hojas amarillo-verdosas son opuestas, redondeandas, carnosas, no tienen venas visibles  y tienen un par de protuberancias (glándulas).',
                    'Las flores blanco-verdosas se dan en espigas y aparecen de julio a octubre. Sus frutos tienen forma de almendra.',
                    'Habita en estuarios y ensenadas, en suelos de arena o arcilla poco inundables. Es susceptible al viento por sus raíces superficiales.'
                ]
            },
            {
                'nombre_comun':'Mangle rojo o colorado, candelón, mangle dulce o tinto',
                'nombre_display':'Mangle rojo',
                'nombre_cientifico':'Rhizophora mangle',
                'conservacion':'Preocupación menor',
                'route':'rojo',
                'datos': [
                    'A veces este mangle entabla una relación de mutuo beneficio con esponjas marinas que se instalan en sus raíces para intercambiar nutrientes.',
                    'Se usa como leña y para construir muebles e instrumentos. La corteza produce un tinte azul que se usa para teñir. También se emplea  como remedio tradicional para picadura de animales marinos, fiebre y otros problemas de salud.'
                ],
                'generalidades': [
                    'Árbol con raíces zancudas. Corteza lisa, grisácea, rojiza a pardo rojiza. Conserva sus hojas color verde oscuro todo el año.',
                    'Sus flores son amarillo verdosas a crema. Tiene un fruto en forma de baya color pardo rojiza. Dentro del fruto se forma una semilla que tiene forma de lanza y flota. El fruto crece con la semilla ya germinada en el árbol.',
                    'Le gusta vivir con los "pies en el agua", en sitios inundados todo el tiempo con una salinidad variable.'
                ]
            },
            {
                'nombre_comun':'Lenguado redondo, comal, tepalcate, sol de mazatlán',
                'nombre_display':'Lenguado redondo',
                'nombre_cientifico':'Achirus mazatlanus',
                'conservacion':'Preocupación menor',
                'route':'lenguado',
                'datos': [
                    'La palabra Achirus significa sin manos y mazatlanus se debe probablemente al sitio (Mazatlán) donde se capturó el especimen descrito por el zoólogo austriaco Franz Steindachner, quien lo describió en 1869.'
                ],
                'generalidades': [
                    'Cuerpo ovalado y los dos ojos del lado derecho, boca pequeña y arqueada. Es de color gris oscuro o parduzco. Tiene 8 líneas oscuras en el costado de los ojos y grupos dispersos de filamentos negros.',
                    'Habita en aguas costeras a menos de 20m de profundidad, entra a lagunas costeras y en agua dulce.',
                    'Se alimenta de crustáceos, peces pequeños, gusanos poliquetos y residuos orgánicos'
                ]
            },
            {
                'nombre_comun':'Camarón blanco del Pacífico',
                'nombre_display':'Camarón blanco/del Pacífico',
                'nombre_cientifico':'Litopaneaus vannamei',
                'conservacion':'No evaluado',
                'route':'camaron',
                'datos': [
                    'Es la especie de cultivo de camarón dominante en el mundo.',
                    'Tiene un par de glándulas en las antenas que tienen una función excretora tal que se podría decir  que "orinan" por las antenas.'
                ],
                'generalidades': [
                    'Es translúcido, azulado u oliva con bandas oscuras, café rojizo en las anténulas, pero se distingue por sus 10 patas  blancas.',
                    'Su cuerpo es alargado y segmentado, cubierto con un exoesqueleto. Tiene trece pares de apéndices (como antenas).',
                    'Crustáceo que se alimenta de plancton y residuos orgánicos.'
                ]
            },
            {
                'nombre_comun':'Constantino, robalo aleta amarilla',
                'nombre_display':'Constantino',
                'nombre_cientifico':'Centropomus robalito',
                'conservacion':'Preocupación menor',
                'route':'constantino',
                'datos': [
                    'De las 12 especies del género Centropomus que existen en el mundo todas se encuentran en México: 6 en el Atlántico y 6 en el Pacífico.',
                    'En el Suroeste del Golfo de California les gusta  alimentarse principalmente de camarón.'
                ],
                'generalidades': [
                    'Pez plateado con una notoria línea lateral oscura, cuerpo alargado y comprimido, aletas amarillas anales y pélvicas.',
                    'Habita bahías, estuarios y arroyos de agua dulce.',
                    'Se alimenta de peces, crustáceos y moluscos.'
                ]
            },
        ]
    },
    { 
        /* TORTUGAS */
        'id':'tortugas',
        'nombre':'TORTUGAS',
        'habitat':'costa',
        'description':'Visita el refugio de tortugas, donde brindamos hogar temporal para la protección y recuperación de estos nobles reptiles.',
        'preguntas': [
            {'pregunta':'¿Qué son las tortugas?', 'respuesta':'Son reptiles que han habitado en la tierra desde hace más de 100 millones de años. Cuentan con grandes aletas y un caparazón estilizado que les ayuda a nadar rápidamente.'},
            {'pregunta':'¿De qué se alimentan las tortugas?', 'respuesta':'Su alimentación puede variar según la especie, pero entre sus favoritos se encuentran medusas, cangrejos y camarones.'},
            {'pregunta':'¿Cuáles son las especies de tortuga más comunes en la bahía de Mazatlán?', 'respuesta':'Tortuga golfina (Lepidochelys olivacea), tortuga carey (Eretmochelys imbricata), tortuga verde (Chelonia mydas) y tortuga caguama (Caretta caretta).'}
        ],
        'especies': [
            {
                'nombre_comun':'Sargento, petaca benderita, pintaño amarillo',
                'nombre_display':'Sargento',
                'nombre_cientifico':'Abudefduf troschelii',
                'conservacion':'Preocupación menor',
                'route':'sargento',
                'datos': [
                    'Los machos de este pez tienen jerarquías muy marcadas entre distintos tipos de machos.',
                    'Sus huevos parecen parches morados en las rocas.',
                    'Se han observado a juveniles de este pez limpiando a otros peces como el salmonete rayado, pajaritos y agujones.',
                ],
                'generalidades': [
                    'Este pez tiene escamas grandes y ásperas en el cuerpo y en la mayor parte de la cabeza. Es de color verde blanquecino o plateado pálido, con 5 barras negras en el lateral y espalda color amarillo brillante.',
                    'El adulto habita en arrecifes rocosos y de coral, y nada en grandes grupos en aguas cálidas.',
                    'Come plancton, invertebrados bentónicos y algas en el arrecife.',
                ],
            },
            {
                'nombre_comun':'Tortuga golfina',
                'nombre_cientifico':'Lepidochelys olivacea',
                'conservacion':'Vulnerable',
                'route':'golfina',
                'datos': [
                    'Además  de la anidación en solitario, esta tortuga tiene un comportamiento de anidación en masa llamada arribazón. Miles de tortugas salen a anidar juntas.',
                    'Es la tortuga marina más abundante en el planeta.',
                    'Hace años su pesca y consumo era legal pero ahora está protegida.',
                ],
                'generalidades': [
                    'Cabeza de forma triangular, su caparazón es color oliva, circular y plano. Cuerpo color verde oliva a gris oscuro.',
                    'Se alimenta de peces, mejillones y crustáceos, especialmente camarones.',
                    'Puede alcanzar los 50kg de peso.',
                ],
            },
            {
                'nombre_comun':'Cochinito, cirujano aleta amarilla, barbero o navajón',
                'nombre_display':'Cochinito',
                'nombre_cientifico':'Prionurus punctatus',
                'conservacion':'Preocupación menor',
                'route':'cochinito',
                'datos': [
                    'Es vegetariano. Come algas según la temporada. Al ser hervíboro, su intestino es más largo que el de los peces carnívoros y su estómago es muscular, como una molleja.',
                    'Es diplomático: usa su columna vertebral pedúncula para abofetear a otros peces con el fin de resolver problemas territoriales.',
                ],
                'generalidades': [
                    'Este pez ovalado y comprimido tiene los ojos en posición muy arriba de la cabeza. Es color gris con pequeñas manchas negras pequeñas y una cola de color amarillo brillante.',
                    'Se puede encontrar en arrecifes poco profundos, en escuelas (grupos).',
                    'Se alimenta de algas en zonas rocosas.',
                ],
            },
        ],
    },
    {
        /* Bahía de las rayas */
        'id':'bahia-rayas',
        'nombre':'BAHÍA/DE LAS RAYAS',
        'habitat':'costa',
        'description':'Vive momentos inolvidables en nuestra bahía de rayas, donde podrás interactuar y aprender de estos singulares y maravillosos peces.',
        'preguntas': [
            {'pregunta':'¿Qué son las rayas?', 'respuesta':'Son peces de esqueleto cartilaginoso, parientes cercanos de los tiburones. A diferencia de ellos, el cuerpo de las rayas es aplanado y sus branquias se ubican en la parte inferior del cuerpo.'},
            {'pregunta':'¿Dónde habitan las rayas?', 'respuesta':'En las zonas cercanas a la costa y en el mar abierto, se distribuyen a distintas profundidades y comúnmente se encuentran asociadas al fondo marino.'},
            {'pregunta':'¿De qué se alimentan las rayas?', 'respuesta':'Entre sus alimentos preferidos se encuentran camarones, almejas, poliquetos (gusanos), peces, caracoles y pulpos.'}
        ],
        'especies': [
            {
                'nombre_comun':'Raya mariposa, raya mariposa californiana',
                'nombre_display':'Raya mariposa',
                'nombre_cientifico':'Gymnura marmorata',
                'conservacion':'Casi amenazada',
                'route':'raya-mariposa',
                'datos': [
                    'Cuenta con un pequeño aparato de veneno para defenderse.',
                    'La hembra guarda los huevos en su cuerpo, los embriones se alimentan de yema y luego se nutren de su madre.',
                    'La hembra es dos veces más grandes que el macho.',
                ],
                'generalidades': [
                    'Raya de disco más ancho que largo, es de color café con manchas pálidas y negruzcas. Cola delgada y extremadamente corta. Hocico chato, boca arqueada, ojos y espiráculo en el dorso, superficie dorsal lisa.',
                    'Vive en bahías, canales y estuarios arenosos o lodosos.',
                    'Come crustáceos y peces pequeños.',
                ],
            },
            {
                'nombre_comun':'Raya látigo largo',
                'nombre_display':'Raya/látigo largo',
                'nombre_cientifico':'Hypanus longus',
                'conservacion':'Vulnerable',
                'route':'raya-latigo',
                'datos': [
                    'Pasan la mayor parte del tiempo en aguas profundas pero las hembras, que son más grandes que los machos, migran a zonas poco profundas en primavera para dar a luz.',
                ],
                'generalidades': [
                    'Llega a medir poco más de un metro. Su disco tiene forma de cometa, hocico largo con punta, ojos grandes, dorso gris, café rojizo o gris olivo, vientre blanco.',
                    'Este pez con esqueleto de cartílago se alimenta de moluscos, cangrejos y peces.',
                ],
            },
            {
                'nombre_comun':'Guitarra punteada',
                'nombre_cientifico':'Pseudobatos glaucostigma',
                'conservacion':'Vulnerable',
                'route':'punteada',
                'datos': [
                    'Tiene pequeñas espinas en su dorso pero es suave al tacto.',
                    'Es la única especie de guitarra con manchas color gris pizarra en la cabeza y la parte superior del cuerpo, así que es fácil reconocerla.',
                ],
                'generalidades': [
                    'Cuerpo parecido al de tiburón y con gran cabeza triangular. Son de color café con manchas de color gris pizarra.',
                    'Nadan cerca del fondo y forman grandes grupos en aguas costeras sobre zonas arenosas y lodosas.',
                    'Los adultos se alimentan de langostinos y cangrejos, los juveniles de crustáceos más pequeños llamados anfípodos.',
                ],
            },
            {
                'nombre_comun':'Raya redonda',
                'nombre_cientifico':'Urobatis halleri',
                'conservacion':'Preocupación menor',
                'route':'raya-redonda',
                'datos': [
                    'Las hembras emiten un campo eléctrico que los machos pueden percibir.',
                    'El apareamiento dura unos 5 minutos.',
                    'Suelen dar a luz a 6 crías vivas y los juveniles  permanecen cerca de la orilla donde hay menos depredadores.',
                ],
                'generalidades': [
                    'Raya de disco redondo, ligeramente más ancho que largo, piel lisa de color café claro o canela con manchas densas café oscuro.',
                    'Vive en fondos arenosos y lodosos de playas y pantanos.',
                    'Se alimenta de invertebrados del suelo marino y peces pequeños.',
                ],
            },
            {
                'nombre_comun':'Chucho dorado, gavilán dorado, raya tecolote',
                'nombre_display':'Chucho dorado',
                'nombre_cientifico':'Rhinoptera steindachneri',
                'conservacion':'Casi amenazado',
                'route':'chucho-dorado',
                'datos': [
                    'A menudo forma escuelas, a veces se asocia con otra raya llamada gavilán pintado.',
                    'En las hembras sólo el ovario y útero izquierdo son funcionales, de hecho tienen pocas crías, sólo un embrión por hembra al año.',
                ],
                'generalidades': [
                    'Tiene forma de diamante con la cabeza saliendo distintivamente del disco; cola muy delgada, piel lisa. Es de color café amarillento pálido, vientre blanco y cola negra.',
                    'Habita sobre fondos blandos, cerca de arrecifes rocosos o de coral y lagunas costeras. Ocasionalmente cerca de la superficie y puede saltar fuera del agua.',
                    'Se alimenta de crustáceos del fondo y moluscos.',
                ],
            },
            {
                'nombre_comun':'Raya moteada',
                'nombre_cientifico':'Urotrygon chilensis',
                'conservacion':'Casi amenazada',
                'route':'raya-moteada',
                'datos': [
                    'Es una especie ovovivípara, es decir, gesta a sus crías en huevos que mantiene en su interior hasta que nacen.'
                ],
                'generalidades': [
                    'Su cuerpo es ovalado, con grandes ojos y una cola delgada sobre la cual presenta una espina. Se distribuye del Golfo de California a Perú y Chile, entre 1 y 60m de profundidad. Su alimento principal son pequeños crustáceos, moluscos, gusanos y peces pequeños.'
                ],
            },
        ]
    },
    {
        /* Rompiente */
        'id':'rompiente',
        'nombre':'ROMPIENTE',
        'habitat':'costa',
        'description':'Siente el poder del mar y déjate impresionar por la imponente fuerza del agua. ¡La vida también florece bajo el oleaje!',
        'preguntas': [
            {'pregunta':'¿Qué es la zona de rompiente?', 'respuesta':'Es la franja de la zona costera en que la energía de las olas da forma a la tierra.'},
            {'pregunta':'¿Qué animales viven en la zona de rompiente?', 'respuesta':'En esta zona puedes encontrar una gran variedad de fauna marina, como caracoles, almejas, balanos, percebes, quitones, erizos de mar y estrellas de mar.'},
            {'pregunta':'¿Cuál es la importancia ecológica de la zona de rompiente?', 'respuesta':'Es hogar de diversas especies marinas y un área de refugio para las larvas de algunos peces y crustáceos.'}
        ],
        'especies': [
            {
                'nombre_comun':'Cucaracha de mar, lengua de perro',
                'nombre_display':'Cucaracha de mar',
                'nombre_cientifico':'Chitón articulatus',
                'conservacion':'Datos deficientes',
                'route':'cucaracha-mar',
                'datos': [
                    'Es un basibionte, lo que significa que otros seres vivos viven sobre él.',
                    'Es el alimento favorito del caracol Plicopúrpura pansa (especie protegida), pero también se ofrece como platillo en algunos restaurantes.',
                ],
                'generalidades': [
                    'Forma oval con una armadura articulada formada por placas duras que protegen al organismo. Debajo tiene un pie muscular y valvas que le ayudan a fijarse mejor a las rocas en donde vive. Son de color verde olivo a café.',
                    'Se les encuentra frecuentemente en zonas de rompiente de olas.',
                    'Se alimenta de restos y desperdicios orgánicos, aunque por mucho tiempo se pensó que eran herbívoros.',
                ],
            },
            {
                'nombre_comun':'Damisela cabezona',
                'nombre_cientifico':'Microspanthodon bairdii',
                'conservacion':'Preocupación menor',
                'route':'damisela-cabezona',
                'datos': [
                    'Depositan sus huevos en grietas entre las rocas en zonas de fuertes corrientes, donde los machos los protegen y airean.',
                ],
                'generalidades': [
                    'El adulto tiene una joroba, puede ser de color gris, café oscuro a cenizo, a veces con cabeza blanca o amarillenta. Los juveniles son de color azul brillante por arriba y anaranjado por debajo.',
                    'Habita en arrecifes rocosos costeros de las olas y las corrientes.',
                    'Se alimentan principalmente de microalgas bentónicas, crustáceos y gusanos.',
                ],
            },
            {
                'nombre_comun':'Damisela gigante',
                'nombre_cientifico':'Microspathodon dorsalis',
                'conservacion':'Preocupación menor',
                'route':'damisela-gigante',
                'datos': [
                    'Defiende sus territorios de alimentación y reproducción ahuyentando a peces y buzos que se acercan mucho.',
                    'Sus huevos se adhieren al sustrato en donde los machos los protegen y airean hasta que nacen los pequeños.',
                ],
                'generalidades': [
                    'Cuerpo grueso y aplastado de color gris azulado oscuro, o casi negro. Llega a medir hasta 31 cm.',
                    'Se alimentan de algas, gusanos marinos, crustáceos y parásitos.',
                ],
            },
            {
                'nombre_comun':'Mejillón californiano',
                'nombre_cientifico':'Mytilus californianus',
                'conservacion':'No evaluado',
                'route':'mejillon-californiano',
                'datos': [
                    'Las camas de mejillones atrapan agua, lodo y materia que proporciona alimento y refugio para una increíble variedad de animales y plantas.',
                    'Los mejillones se pueden volver peligrosos para consumo humano cuando hay florecimientos algales como las mareas rojas. Son filtradores y se alimentan de los dinoflagelados que forman estas mareas y que pueden producir toxinas.',
                ],
                'generalidades': [
                    'La concha es de color negro azulado, a menudo con válvulas blancas erosionadas y más oscuras en los márgenes. El extremo de atrás de la concha es puntiagudo.',
                    'Vive en rocas expuestas al oleaje y pilotes de muelles. Abundante en la zona intermareal.',
                    'Para alimentarse filtra agua y atrapa materia orgánica y plancton.',
                ],
            },
            {
                'nombre_comun':'Ostión de roca',
                'nombre_cientifico':'Striostrea prismatica',
                'conservacion':'No evaluado',
                'route':'ostion-roca',
                'datos': [
                    'Los ostiones son excelentes indicadores de la salud del medio ambiente marino, funcionan como bio-monitores porque filtran y acumulan contaminantes.',
                ],
                'generalidades': [
                    'Concha ovalada o alargada, pesada y gruesa. Por fuera es de color café-púrpura y por dentro, blanco iridiscente con parches de color café.',
                    'Vive en la zona rocosa donde sube y baja la marea.',
                    'Se alimenta de fitoplancton y materia orgánica.',
                ],
            },
            {
                'nombre_comun':'Erizo de mar blanco o café',
                'nombre_display':'Erizo/de mar blanco',
                'nombre_cientifico':'Tripneustes depressus',
                'conservacion':'No evaluado',
                'route':'erizo',
                'datos': [
                    'Se ha probado que el líquido celómico de este erizo de mar tiene actividad antiviral contra la rabia y el herpesvirus porcino.',
                    'Crece muy rápido comparado con la mayoría de los erizos.',
                    'Tienen células fotorreceptoras y se ha sugerido que todo el cuerpo del erizo funciona como un gran ojo compuesto.',
                ],
                'generalidades': [
                    'Cuerpo globoso comprimido con una base café-rojiza y espinas con una base del mismo color y blanco – amarillento del medio hacia la punta.',
                    'Habita en fondos rocosos cubiertos por algas, pastos marinos, planicies de arrecifes de coral.',
                    'Más común en aguas poco profundas. Se alimenta de algas y algunos invertebrados.',
                ],
            },
        ]
    },

    /* TIERRA */
    {
        /* TERRARIO */
        'id':'terrario',
        'nombre':'TERRARIO',
        'habitat':'tierra',
        'description':'Descubre fascinantes seres de escamas y pieles resbalosas. Conoce a los eslabones clave para que la vida saliera del agua y se adaptara a la tierra. Estamos seguros de que te encantará.',
        'preguntas': [
            {'pregunta':'¿Qué es la herpetofauna?', 'respuesta':'Es el conjunto de animales anfibios y reptiles conocidos por no contar con pelaje y ser de “sangre fría”, es decir, que no regulan su temperatura (como nosotros) y dependen del ambiente para mantener su temperatura corporal ideal. México es el segundo lugar mundial en riqueza de herpetofauna.'},
            {'pregunta':'¿Qué son los anfibios?', 'respuesta':'Son animales de piel lisa, sin escamas, plumas o pelo. Viven cerca del agua, ya que sus huevos dependen de la humedad para desarrollarse. A este grupo pertenecen las ranas, sapos, salamandras y axolotes.'},
            {'pregunta':' ¿Qué son los reptiles?', 'respuesta':'Se trata de animales conocidos por su piel con escamas y cuerpos que les permiten moverse en ambientes secos y extremos. Fueron los primeros en ser independientes del agua para su reproducción, ya que sus huevos tienen un cascaron duro que los protege de la pérdida de agua. Animales como serpientes, tortugas, lagartijas e iguanas forman este grupo.'},
        ],
        'especies': [
            {
                'nombre_comun':'Sapito de Mazatlán o sapo sinaloense',
                'nombre_display':'Sapito de Mazatlán',
                'nombre_cientifico':'Incilius mazatlenensis',
                'conservacion':'Preocupación menor',
                'route':'sapito-mazatlan',
                'datos': [
                    'Es una especie que solo se encuentra en México.',
                    'Se descubrió en Mazatlán, Sinaloa, de ahí su nombre.',
                ],
                'generalidades': [
                    'Sapo robusto y de piel rasposa. Llega a medir casi 9 cm.',
                    'Vive en la selva baja caducifolia, bosques tropicales y secos, así como en pinares bajos.',
                    'Se alimenta de escarabajos, hormigas y otros insectos.',
                ],
            },
            {
                'nombre_comun':'Boa común, ilama, mazacoatl, limacoa',
                'nombre_display':'Boa común',
                'nombre_cientifico':'Boa constrictor imperator',
                'conservacion':'Preocupación menor',
                'route':'boa-comun',
                'datos': [
                    'Llega a vivir hasta 25 o 30 años.',
                    'No es venenosa.',
                    'Como su nombre lo indica: constrictor, aprieta a sus presas con el cuerpo para atraparlas, asfixiarlas y luego comerlas, pero no puede comer presas demasiado grandes. Al parecer algunas boas han muerto por intentar comer tlacuaches.',
                ],
                'generalidades': [
                    'Mide de 2 a 3 metros. Cuerpo gris, con marcas dorsales. Cabeza con tres franjas oscuras.',
                    'Es más activa en la tarde y noche. Es solitaria.',
                ],
            },
            {
                'nombre_comun':'Escorpión mexicano, lagarto enchaquirado.',
                'nombre_display':'Escorpión mexicano',
                'nombre_cientifico':'Heloderma horridum',
                'conservacion':'Preocupación menor',
                'route':'escorpion-mexicano',
                'datos': [
                    'Es una de las dos lagartijas venenosas que existen en el mundo.',
                    'Utiliza su veneno como defensa, pero no lo inyecta sino que escurre por sus dientes acanalados desde unas glándulas.',
                    'Es de gran importancia médica pues las toxinas de su veneno tienen usos potenciales en medicina.',
                ],
                'generalidades': [
                    'Saurio de unos 40 centímetros, de cabeza y cuerpo grande, cola corta.',
                    'Habita en la costa del Pacífico mexicano. Su mayor actividad es durante la madrugada o al anochecer. Son lentos y tranquilos.',
                    'Se alimenta de huevos y presas pequeñas. Cada vez son más escasos.',
                ],
            },
        ]
    },
    {
        /* AVIARIO */
        'id':'aviario',
        'nombre':'AVIARIO',
        'habitat':'tierra',
        'description':'Entre jardines y una grandiosa vista del acuario, conoce los colores y cantos de bellas aves.',
        'preguntas': [
            {'pregunta':'¿Cuántas especies de aves hay en México?', 'respuesta':'México es un país con gran variedad de aves, ocupa el 1er lugar en Norteamérica con mil 107 especies y el doceavo a nivel mundial. Dentro del gran acuario podrás conocer algunas de ellas.'},
            {'pregunta':'¿Por qué migran las aves?', 'respuesta':'Algunas aves viajan en búsqueda de ambientes más favorables para su reproducción y alimentación, México es uno de los lugares preferidos de muchas de ellas.'},
            {'pregunta':'¿Por qué cantan las aves?', 'respuesta':'Usan su canto para comunicarse entre ellas y cada especie tiene un sonido que las distingue. En tu visita podrás escucharlas.'},
        ],
        'especies': [
            {
                'nombre_comun':'Capiro',
                'nombre_cientifico':'Albizia sinaloensis',
                'conservacion':'Pendiente',
                'route':'capiro',
                'datos': [
                    'Pendiente',
                ],
                'generalidades': [
                    'Pendiente',
                ],
            },
        ]
     },
    {
        /* JARDINES */
        'id':'jardines',
        'nombre':'JARDINES',
        'habitat':'tierra',
        'description':'Asómbrate y conoce la variedad de plantas que forman parte del acuario; seres vivos que nos proveen de oxígeno, refrescan y alegran te acompañarán en tu recorrido.',
        'preguntas': [
            {'pregunta':'¿Qué es la vegetación?', 'respuesta':'Es el nombre que se le da a las comunidades de plantas que conviven y se desarrollan en un lugar o región.'},
            {'pregunta':'¿Qué importancia tienen las plantas?', 'respuesta':'Nos permiten tener un ambiente agradable, ya que con apoyo de sus hojas, ramas y tallos, guardan humedad y regulan la temperatura. Además, capturan el dióxido de carbono y producen oxígeno.'},
            {'pregunta':'¿Qué es una especie endémica?', 'respuesta':'Son aquellas especies (incluyendo a las plantas) que viven en un área limitada. Dentro de los jardines podrás encontrar plantas que solo se encuentran en esta región.'},
        ],
        'especies': [
            {
                'nombre_comun':'Capiro',
                'nombre_cientifico':'Albizia sinaloensis',
                'conservacion':'No evaluado',
                'route':'capiro',
                'datos': [
                    'Es un árbol perennifolio, esto quiere decir que conserva su follaje durante todo el año.',
                ],
                'generalidades': [
                    'Este árbol puede crecer hasta 30m de altura. Sus hojas se distribuyen de forma alterna, su corteza presenta una coloración amarillenta y suele tener muchas flores extendidas. En México se encuentra en los estados de Sinaloa y Sonora.',
                ],
            },
            {
                'nombre_comun':'Venadillo',
                'nombre_cientifico':'Swietenia humilis',
                'conservacion':'En peligro',
                'route':'venadillo',
                'datos': [
                    'Es apreciado por su madera que se utiliza para la elaboración de muebles finos, instrumentos musicales y decoración de interiores.',
                ],
                'generalidades': [
                    'Puede medir hasta 25m de altura y hasta un metro de diámetro. Comienza a florecer cuando tiene entre 15 - 25 años, entre los meses de marzo y abril.',
                ],
            },
            {
                'nombre_comun':'Huanacaxtle',
                'nombre_cientifico':'Enterolobium cyclocarpum',
                'conservacion':'Preocupación menor',
                'route':'huanacaxtle',
                'datos': [
                    'Es de importancia comercial por su madera en la ebanistería y para uso artesanal. Sus frutos son usados como alimento para el ganado y son también de uso medicinal tradicional.',
                ],
                'generalidades': [
                    'Es un árbol grande y llamativo que puede medir hasta 45m de altura. Esta especie es nativa de México, Centroamérica y Norte de Sudamérica. Sus frutos surgen entre los meses de mayo y julio.',
                ],
            },
            {
                'nombre_comun':'Amapa',
                'nombre_cientifico':'Tabebuia rosea',
                'conservacion':'Preocupación menor',
                'route':'amapa',
                'datos': [
                    'Sus hojas y corteza son utilizadas para tratar la fiebre, tifoidea y parásitos. Sus bellas flores tienen forma de trompeta y atraen a diversos polinizadores.',
                ],
                'generalidades': [
                    'Es un árbol caducifolio, es decir, pierde sus hojas en temporada de secas. Suele medir entre  15 - 25m y hasta 30m. Florece de febrero a junio y da frutos entre los meses de marzo y junio.',
                ],
            },
            {
                'nombre_comun':'Tabachín',
                'nombre_cientifico':'Delonix regia',
                'conservacion':'Preocupación menor',
                'route':'tabachin',
                'datos': [
                    'Sus hojas y flores son empleadas en la medicina tradicional de algunas etnias. Se le considera un árbol de crecimiento rapido ya que crece de 1 a 2 metros por año.',
                ],
                'generalidades': [
                    'Se distribuye en ambientes de clima cálido con humedad, llegan a medir de 8 a 12 metros de altura y presentan unas llamativas flores en colores rojo, naranja y amarillo.',
                ],
            },
            {
                'nombre_comun':'Guamúchil',
                'nombre_cientifico':'Pithecellobium dulce',
                'conservacion':'Preocupación menor',
                'route':'guamuchil',
                'datos': [
                    'Produce un fruto comestible en una vaina que tiende a enrollarse en forma de espiral. Tambien ha sido utilizado con propósitos de reforestación y la producción de leña.',
                ],
                'generalidades': [
                    ' Nativo de México, Centroamérica y Sudamérica. Son de crecimiento rápido y llegan a alcanzar los 25 metros de altura.',
                ],
            },
            {
                'nombre_comun':'Guayacán',
                'nombre_cientifico':'Tabebuia guayacan',
                'conservacion':'Preocupación menor',
                'route':'guayacan',
                'datos': [
                    'Es apreciado en algunas etnias por su uso como planta medicinal.',
                ],
                'generalidades': [
                    'Es un árbol nativo del norte de méxico de ambientes cálidos; presenta flores color amarillo y puede llegar a crecer hasta los 15 metros de altura.',
                ],
            },
            /*
            {
                'nombre_comun':'Palma de chicle',
                'nombre_cientifico':'Dioon spinulosum',
                'conservacion':'En peligro',
                'route':'palma-chicle',
                'datos': [
                    'Su fruto es el más grande de las cícadas y uno de los más grande del grupo de las gimnospermas, llegando a pesar hasta 25 kg. Sus hojas se utilizan en ceremonias.',
                ],
                'generalidades': [
                    'De tronco robusto, llegan a crecer hasta 15metros de altura y agrupa sus hojas en forma de corona. Su distribución comprende desde el centro de México hasta Honduras.',
                ],
            },
            */
            {
                'nombre_comun':'Apompo, Guayana, castaño silvestre, money tree',
                'nombre_display':'Apompo',
                'nombre_cientifico':'Pachira aquatica',
                'conservacion':'Preocupación menor',
                'route':'apompo',
                'datos': [
                    'Sus hojas, flores y nueces son todos comestibles.',
                    'Sus flores están entre las flores de árboles más grandes del mundo.',
                ],
                'generalidades': [
                    'Árbol de zona tropical, nativo de América Central y sur. Puede llegar a medir hasta 12m.',
                    'Sus flores se abren como cáscaras de plátano, tienen pétalos largos y estrechos de color amarillo verdoso o crema. Produce nueces que crecen en vainas verdes.',
                ],
            },
        ]
     },

    /* MAR */
    { 
        'id':'punto-pulmo', 'nombre':'PUNTO PULMO', 'habitat':'mar',
        'description':'Una ventana al fascinante mundo del coral vivo.',
        'preguntas': [
            {'pregunta': '¿Qué es un coral?', 'respuesta': 'Son animales marinos que viven en estrecha relación con algas microscópicas, las cuales se introducen en el coral y les proveen alimento.'},
            {'pregunta': '¿Cuántas especies de corales formadores de arrecifes existen en el Mar de Cortés?', 'respuesta': 'Existen 14 especies de corales formadores de arrecifes (hermatípicos) y 11 de ellas se encuentran en el Parque Nacional Cabo Pulmo.'},
            {'pregunta': '¿En qué profundidades podemos encontrar a los corales formando arrecifes arrecifes?', 'respuesta': 'Es común encontrar arrecifes entre los 0 a 12 m, debido a que conforme aumenta la profundidad hay menor luz, la cual es una fuente de energía para el crecimiento de los corales.'},
        ],
        'especies': [
            {
                'nombre_comun':'Cirujano reo',
                'nombre_cientifico':'Acanthurus triostegus',
                'conservacion':'Preocupación menor',
                'route':'cirujano-reo',
                'datos': [
                    'Pueden detectar vibraciones y poseen un fuerte sentido del olfato y el sonido.',
                    'Pueden fingir la muerte exhibiendo inmovilidad tónica para evitar la depredación.',
                    'En sus intestinos viven unas de las bacterias más grande del mundo y se cree que éstas le ayudan a digerir los alimentos.',
                ],
                'generalidades': [
                    'Tiene forma de óvalo alargado, cabeza con perfil empinado, ojos muy arriba de la cabeza. Es de color blancuzco a gris verdoso claro con 6 barras negras angostas en cabeza y cuerpo.',
                    'De adultos habitan en arrecifes de laguna y mar con sustrato duro; mientras que los jóvenes son  abundantes en pozas de marea.',
                    'Se alimentan de microalgas.',
                ],
            },
            {
                'nombre_comun':'Jaqueta de Cortés, Castañeta azulada',
                'nombre_display':'Castañeta azulada',
                'nombre_cientifico':'Stegastes rectifraenum',
                'conservacion':'Preocupación menor',
                'route':'castaneta-azulada',
                'datos': [
                    'Al comer algas en su territorio de manera constante, ayuda a regular su crecimiento y a mejorar la productividad del arrecife.',
                    'Es un pez endémico y uno de los más abundantes a lo largo de la costa del Pacífico mexicano.',
                ],
                'generalidades': [
                    'Pez de cuerpo comprimido ovalado. Los adultos son de color café, oscuro el cuerpo y cabeza pálida. Los juveniles son de color azul brillante y un par de rayas azul neón en la parte superior de la cabeza. Tienen una mancha negra en la aleta dorsal.',
                    'Habita arrecifes rocosos poco profundos de la zona de oleaje.',
                    'Son omnívoros, comen de todo un poco.',
                ],
            },
            {
                'nombre_comun':'Arcoiris Cortés',
                'nombre_cientifico':'Thalassoma lucasanum',
                'conservacion':'Preocupación menor',
                'route':'arcoiris-cortes',
                'datos': [
                    'Prácticamente desaparecen cuando la temperatura del agua baja de los 27°C.',
                    'Tienen un movimiento particular de arriba a abajo cuando usan sus aletas pectorales. ¡Ven a verlos!',
                    'Hay dos tipos de machos y dos sistemas de reproducción, y a veces las  hembras pueden transformarse en machos.',
                ],
                'generalidades': [
                    'Cuerpo delgado y alargado. Las hembras y los machos  tienen un cuerpo rayado con una franja amarilla y otra azul verdoso, una línea amarilla, otra roja y vientre blanco.',
                    'Otro tipo de machos son de cabeza color azul oscuro, una barra amarilla ancha detrás de su cabeza, cola azulada, cuerpo púrpura y aletas pectorales amarillas.',
                    'Vive en agregaciones en y alrededor de arrecifes rocosos y de coral, en pozas intermareales.',
                    'Come plancton y pequeños invertebrados.',
                ],
            },
            /*
            {
                'nombre_comun':'Gorgonias marinas',
                'nombre_cientifico':'Eugorgia sp.',
                'conservacion':'No evaluado',
                'route':'gorgonias-marinas',
                'datos': [
                    'Pertenecen a una familia de corales conocidos como "Abanicos de mar".',
                ],
                'generalidades': [
                    'Es un género de corales que se distribuye en el Océano Pacífico Oriental. Su cuerpo cuenta con',
                ],
            },
            */
            {
                'nombre_comun':'Coral copa naranja ',
                'nombre_cientifico':'Tubastrea occinea',
                'conservacion':'No evaluado',
                'route':'coral',
                'datos': [
                    'Esta especie puede vivir sobre materiales artificiales como el granito, cemento y el acero.',
                    'Es una especie invasora, se extendido por el Atlántico occidental durante 60 años.',
                    'Pueden crecer en completa oscuridad siempre que puedan capturar suficiente comida.',
                ],
                'generalidades': [
                    'Las colonias son de color naranja o rojo, amarillo brillante, rojo rosado a rosa. Sus pólipos suelen extenderse por la noche.',
                    'Estos corales no forman arrecifes pero pueden vivir cerca de ellos, en rocas y hasta en aguas frías.',
                ],
            },
            {
                'nombre_comun':'Pavona gigantea',
                'nombre_cientifico':'Pavona gigantea',
                'conservacion':'Preocupación menor',
                'route':'pavona',
                'datos': [
                    'Sus colonias son masivas y son importantes formadoras de arrecifes.',
                    'Es uno de los corales más comunes de la costa del Pacífico mexicano.',
                ],
                'generalidades': [
                    'Es un coral grande, grueso, incrustante, de color verde, gris y marrón. Superficie superior casi plana o diversamente ondulada y desigual, cubierta de celdas grandes, distantes y estrelladas.',
                    'Habita en fondos rocosos de aguas tropicales poco profundas.',
                    'Recibe alimento de unas algas con las que se asocia y también captura algo de plancton.',
                ],
            },
        ]
    },
    { 
        'id':'ciudad-coral', 'nombre':'CIUDAD CORAL', 'habitat':'mar',
        'description':'Los arrecifes son las metrópolis del océano, ¡déjate atrapar por los colores, vida y luces de la ciudad coral!',
        'preguntas': [
            {'pregunta': '¿Qué es un coral?', 'respuesta': 'Son animales marinos que viven en estrecha interacción con algas microscópicas, las cuales se introducen en el coral y a través de la fotosíntesis les proveen de energía.'},
            {'pregunta': '¿Cómo se forman los arrecifes?', 'respuesta': 'Los pequeños corales individuales (pólipos) forman colonias que se protegen con un esqueleto de carbonato de calcio. Estas estructuras en conjunto constituyen a los arrecifes.'},
            {'pregunta': '¿Qué importancia tienen los arrecifes de coral?', 'respuesta': 'Son ecosistemas altamente diversos, es decir, albergan una gran variedad de vida marina. Sirven como área de refugio y alimentación de distintas especies.'},
        ],
        'especies': [
            /*
            {
                'nombre_comun':'Botete de aletas punteadas',
                'nombre_display':'Botete/de aletas punteadas',
                'nombre_cientifico':'Arothron meleagris',
                'conservacion':'Preocupación menor',
                'route':'botete-aletas',
                'datos': [
                    'Se inflan como mecanismo de defensa. Al igual que muchos botetes/globos, presentan una neurotoxina en su piel, vísceras y gónadas. Su distribución dentro del Pacífico Oriental comprende desde las costas de Guaymas Sonora hasta el Ecuador.',
                ],
                'generalidades': [
                    'Pez de cuerpo redondeado, de color negro uniforme cubierto con numerosas manchas blancas pequeñas. Vive en áreas de arrecifes rocosos y de coral. Se alimenta de coral, pequeños animales marinos, esponjas, algas y erizos de mar.',
                ],
            },
            
            {
                'nombre_comun':'Cirujano aleta amarilla',
                'nombre_display':'Cirujano/aleta amarilla',
                'nombre_cientifico':'Acanthurus xanthopterus',
                'conservacion':'Preocupación menor',
                'route':'cirujano-gris',
                'datos': [
                    'Es venenoso y es el más grande de los Peces Cirujano.',
                    'En aguas mexicanas tienen una distribución limitada, solo se encuentra en el extremo sur del Mar de Cortés entre La Paz y Cabo San Lucas, y a lo largo de la costa del continente desde Acapulco hasta Guatemala.',
                ],
                'generalidades': [
                    'Pez de color gris violáceo, aletas parcialmente amarillas.',
                    'Vive en varios hábitats de arrecifes, laderas de arena y lagunas. Los juveniles prefieren aguas costeras poco profundas, protegidas y turbias, mientras que los adultos prefieren áreas más profundas de bahías y lagunas.',
                    'Se alimentan durante el día de diatomeas, detritos de arena, algas filamentosas, hidroides y trozos de pescado.'
                ],
            },
            */
            {
                'nombre_comun':'Cirujano cola amarilla',
                'nombre_display':'Cirujano/cola amarilla',
                'nombre_cientifico':'Prionurus laticlavius',
                'conservacion':'Preocupación menor',
                'route':'cirujano',
                'datos': [
                    'Generalmente se mueven en cardúmenes y pueden sumergirse hasta los 30m de profundidad algo no muy común en peces como ellos. Se distribuyen del Golfo de California a El Salvador, incluyendo las Islas Revillagigedo.',
                ],
                'generalidades': [
                    'De cuerpo ovalado y comprimido color gris, con pequeñas manchas negras y presenta dos barras oscuras en la cabeza, su cola es color amarillo y cuenta con una boca pequeña que se proyecta. Vive en fondos del área costera, asociado a arrecifes.',
                    'Se alimenta de microalgas y macroalgas bentónicas, así como hierbas marinas.',
                ],
            },
            {
                'nombre_comun':'Mero pintado o cabrilla piedrera',
                'nombre_display':'Mero pintado',
                'nombre_cientifico':'Epinephelus labriformis',
                'conservacion':'Preocupación menor',
                'route':'mero',
                'datos': [
                    'Es un pez hermafrodita protógino, es decir, funciona primero como hembra y luego de transforma en macho.',
                ],
                'generalidades': [
                    'Pez robusto de color café rojizo que está cubierto de manchas blancas de tamaños irregulares. Tienen una marca negra característica en la base de su aleta caudal que baja hacia los costados.',
                    'Habita en áreas rocosas de aguas costeras.',
                    'Durante el día se alimenta de peces pequeños y de crustáceos durante la noche.',
                ],
            },
            {
                'nombre_comun':'Ángel de Clarión',
                'nombre_cientifico':'Holacanthus clarionensis ',
                'conservacion':'Vulnerable',
                'route':'angel',
                'datos': [
                    'Es endémica de las islas Revillagigedo y Clipperton, a veces presente en el Golfo de California.',
                    'Es el principal pez limpiador de las mantarrayas (Manta birostris).',
                ],
                'generalidades': [
                    'Pez de cuerpo rectangular. Generalmente es de cuerpo color naranja parduzco, cabeza café oscuro, una banda anaranjada  brillante detrás de la cabeza y una aleta caudal naranja. Los juveniles son de color naranja parduzco con rayas azules estrechas en la cabeza y los lados.',
                    'Come esponjas, tunicados, hidrozoarios, crustáceos, moluscos, plancton y algas.',
                    'Vive en arrecifes rocosos, los juveniles son solitarios y territoriales, los adultos se mueven sobre el fondo en grupos de hasta 30 individuos.',
                ],
            },
            {
                'nombre_comun':'Ángel real o Ángel rey',
                'nombre_display':'Ángel real',
                'nombre_cientifico':'Holacanthus passer',
                'conservacion':'Preocupación menor',
                'route':'angel_real',
                'datos': [
                    'Las hembras son más territoriales y forman vínculos de pareja con los machos.',
                    'Se ha observado que los juveniles limpian a otros peces.',
                ],
                'generalidades': [
                    'Pez de forma rectangular. El adulto es de color gris azul oscuro con una estrecha banda blanca vertical, aletas color amarillo-naranja, y una mancha naranja  en la esquina de la boca. Los juveniles son multicolores de rayas verticales.',
                    'Se alimenta de esponjas, invertebrados, plancton y algas.',
                    'Vive en arrecifes rocosos y de coral.',
                ],
            },
            {
                'nombre_comun':'Ángel de Cortés',
                'nombre_cientifico':'Pomacanthus zonipectus',
                'conservacion':'Preocupación menor',
                'route':'angel-cortes',
                'datos': [
                    'Los adultos suelen desplazarse en parejas o en agregaciones, mientras que los juveniles son territoriales y se desplazan de manera individual.',
                    'Suelen relacionarse con una sola pareja.',
                ],
                'generalidades': [
                    'Cuerpo alto y comprimido, con un hocico corto; boca pequeña con dientes en forma de cerdas. Su color es grisáceo con bandas negras y amarillas, cola color amarillo pálido. Los juveniles se distinguen por contar con bandas azules y amarillas en su cuerpo.',
                    'Se alimenta de corales suaves, estrellas de mar, pepinos, anémonas y esponjas.',
                ],
            },
            {
                'nombre_comun':'Mariposa rayada o Ídolo moro',
                'nombre_display':'Mariposa rayada',
                'nombre_cientifico':'Zanclus cornutus',
                'conservacion':'Preocupación menor',
                'route':'mariposa-rayado',
                'datos': [
                    'Son peces activos que disfrutan pasar el día explorando su habitat. Por lo general son sociales con peces de otras especies.',
                ],
                'generalidades': [
                    'Tiene su cuerpo en forma de disco con una aleta dorsal alargada similar a un látigo. Su coloración es blanca con franjas de color negro, cuenta con 2 manchas color amarillo, una en la mitad trasera de cuerpo y otra cercana a su boca.',
                    'Vive asociado a los arrecifes de coral, donde se alimenta de algas, esponjas, krill o pequeños peces.',
                ],
            },
        ]
    },
    { 
        'id':'establo-marino', 'nombre':'ESTABLO MARINO', 'habitat':'mar',
        'description':'La sutil cabalgata de estos magníficos peces te va a encantar. No pierdas la oportunidad de ver a los caballitos de mar en su establo marino.',
        'preguntas': [
            {'pregunta': '¿Qué es un caballito de mar?', 'respuesta': 'Los caballitos de mar son peces, los cuales reciben su nombre común debido a la forma de su cabeza.'},
            {'pregunta': '¿Cuál es el hábitat de los caballitos de mar?', 'respuesta': 'Viven en aguas cálidas, comunmente se asocian a arrecifes marinos y zonas de pastos marinos.'},
            {'pregunta': '¿Cuál es la especie de caballito de mar más grande?', 'respuesta': 'El caballito de mar del Pacífico (Hippocampus ingens) es una de las especies más grandes del mundo. Alcanza hasta 30 cm de largo.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pez pipa chica o enana',
                'nombre_display':'Pez pipa chica',
                'nombre_cientifico':'Doryrhamphus excisus',
                'conservacion':'Preocupación menor',
                'route':'pipa_chica',
                'datos': [
                    'Utiliza su hocico tubular para ingerir su comida de manera similar al caballito de mar.',
                    'Es monógamo y el macho lleva los huevos en una bolsa de cría que se encuentra debajo de la cola.',
                ],
                'generalidades': [
                    'Hocico largo con cresta central espinosa. Es de color rojizo a café, con una banda azul en la parte superior del cuerpo y una negruzca a los lados del hocico.',
                    'Es poco común, se camufla bien; prefiere estar en grietas en rocas y corales y áreas debajo de repisas.',
                    'Se alimenta de pequeños crustáceos y plancton.',
                ],
            },
            {
                'nombre_comun':'Caballito de mar del Pacífico',
                'nombre_display':'Caballito de mar/del Pacífico',
                'nombre_cientifico':'Hippocampus ingens',
                'conservacion':'Vulnerable ',
                'route':'caballito',
                'datos': [
                    'Es una de las más grandes de las cerca de 50 especies de caballitos conocidas en el mundo.',
                    'No tienen dientes ni estómago y succiona su alimento, como si comiera con popote.',
                    'La hembra deposita los huevos en la bolsa de incubación del macho donde permanencen hasta nacer los pequeños caballitos.',
                ],
                'generalidades': [
                    'Pez de cuello curvo con la cabeza casi en ángulo recto respecto al cuerpo, el cual está cubierto de anillos óseos.',
                    'Hocico alargado, estructura en forma de corona en la cabeza. Es de color variable para camuflarse en su medio.',
                    'Es más activo en la noche y habita en aguas costeras.',
                    'Se alimenta de zooplancton y crustáceos.',
                ],
            },
        ]
     },
     { 
        'id':'espejos', 'nombre':'ESPEJOS', 'habitat':'mar',
        'description':'Una gran columna de agua se eleva cerca del corazón del acuario. En ella, espejos vivientes nos ofrecen un hermoso espectáculo de sincronía y brillos.',
        'preguntas': [
            {'pregunta': '¿Por qué les llaman peces espejo?', 'respuesta': 'Los particulares peces que encontrarás en este hábitat son conocidos como peces espejo, esto se debe al reflejo que dan sus escamas en un tono plateado, su brillo te sorprenderá.'},
            {'pregunta': '¿Qué comen los peces espejo?', 'respuesta': 'Se trata de un pez carnívoro, que come pequeños pulpos, peces, camarones, y calamares.'},
            {'pregunta': '¿Dónde encontramos a los peces espejo?', 'respuesta': 'La especie Selene brevoortii se distribuye desde las costas de Baja California y el Mar de Cortés hasta el ecuador.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pez espejo, papelillo, jorobada.',
                'nombre_display':'Pez espejo',
                'nombre_cientifico':'Selene brevoortii',
                'conservacion':'Preocupación menor',
                'route':'pez-espejo',
                'datos': [
                    'Su color plateado (pigmentos de guanina) le da a su  cuerpo un efecto espejo que puede modificar para atenuar la luz o hacerla más fuerte y así mejorar su camuflaje.',
                ],
                'generalidades': [
                    'Pez de aguas costeras con cuerpo pentagonal comprimido, color plateado. Llega a medir hasta 25 cm.',
                    'Vive en aguas costeras poco profundas en pequeños bancos cerca del fondo.',
                    'Se alimenta de calamares pequeños, camarones, gusanos poliquetos y peces pequeños.',
                ],
            },
        ]
    },
    { 
        'id':'oceanica', 'nombre':'OCEÁNICA', 'habitat':'mar',
        'description':'Llega hasta el corazón del mar: experimenta la increíble belleza y majestuosidad de la vida oceánica. El hábitat más grande del acuario, con una ventana de más de 13 metros de largo, te dejará sin aliento.',
        'preguntas': [
            {'pregunta': '¿Cuál es la importancia del océano?', 'respuesta': 'El océano y su vida dan forma a las características de la tierra y permiten que la vida en ella sea habitable. Tiene una relación estrecha con el clima, ya que sus aguas absorben una gran cantidad de calor y dióxido de carbono. Además de estos beneficios naturales, nos proporcionan recursos como alimento y energía.'},
            {'pregunta': '¿Qué son las especies pelágicas?', 'respuesta': 'Son especies que se encuentran nadando libremente en el mar y no suelen permanecer cerca de la costa.'},
            {'pregunta': '¿Cuál es la edad del Mar de Cortés?', 'respuesta': 'El magnífico Mar de Cortés se originó hace 5-6 millones de años y es el mar más joven del mundo.'},
        ],
        'especies': [
            {
                'nombre_comun':'Tiburón puntas negras',
                'nombre_display':'Tiburón/puntas negras',
                'nombre_cientifico':'Carcharhinus limbatus',
                'conservacion':'Casi amenazado',
                'route':'tiburon-puntas-negras',
                'datos': [
                    'En 2008 se encontró evidencia en DNA de que una hembra fertilizó sus huevos sin ayuda de un macho.',
                    'Saltan por encima del agua dando giros y caen sobre sus espaldas, a veces como una estrategia de alimentación, para golpear bancos de peces.',
                ],
                'generalidades': [
                    'Tiburón de hocico más o menos largo y puntiagudo al final, ojos pequeños. Mide entre 1.20 y 1.94m, siendo los machos más grandes que las hembras.',
                    'Habitan tanto en la costa como en mar adentro por todo el planeta.',
                    'Es un cazador activo de peces, pequeños tiburones, rayas, cefalópodos y crustáceos.',
                ],
            },
            {
                'nombre_comun':'Tiburón colorado',
                'nombre_cientifico':'Carcharhinus altimus',
                'conservacion':'Casi amenazado',
                'route':'tiburon-colotrado',
                'datos': [
                    'Durante el día, nada en aguas profundas pero durante la noche se mueve más cerca de la superficie.',
                    'Llega a medir casi 3 metros y puede llegar a vivir unos 21 años.',
                ],
                'generalidades': [
                    'Cuerpo robusto, hocico largo, ancho y ligeramente puntiagudo; ojos redondeados; coloración gris, blancuzca hacia la parte ventral.',
                    'Se alimenta de peces, mantarrayas y sepias, pero también puede comerse a otros tiburones.',
                    'Suele frecuentar relieves marinos accidentados: deniveles y fracturas del suelo.',
                ],
            },
            {
                'nombre_comun':'Tiburón aleta de cartón',
                'nombre_display':'Tiburón aleta/de cartón',
                'nombre_cientifico':'Carcharinus plumbeus',
                'conservacion':'Vulnerable ',
                'route':'tiburon-trozo',
                'datos': [
                    'Las hembras adultas suelen desarrollar una capa de piel más gruesa que los machos. Esta piel engrosada sirve como protección en sus rituales de apareamiento y en las condiciones difíciles del entorno rocoso y coralino donde viven.',
                ],
                'generalidades': [
                    'Cuerpo robusto con nariz redondeada y ojos pequeños, coloración gris-café y blanco en el vientre.',
                    'Se le encuentra tanto en costa como en altamar, con mayor frecuencia en bahías, desembocadura de ríos y puertos evitando playas arenosas, arrecifes de coral y fondos rocosos.',
                    'Se alimenta de peces, tiburones pequeños, pulpo, calamar, camarón, rayas, caracoles y babosas marinas, los más jóvenes prefieren cangrejo azul y camarón.',
                ],
            },
        ]
    },
    { 
        'id':'migracion', 'nombre':'MIGRACIÓN', 'habitat':'mar',
        'description':'Sé testigo de las increíbles travesías de los grandes y pequeños viajeros que llegan al Mar de Cortés.',
        'preguntas': [
            {'pregunta': '¿Qué es la migración?', 'respuesta': 'Es el desplazamiento de una especie de una zona a otra por periodos definidos. Puede ocurrir por distintos motivos, entre los que se destacan la necesidad de sitios propicios para el descanso, alimentación y reproducción.'},
            {'pregunta': '¿Qué características hacen que el Mar de Cortés sea un lugar ideal para especies migratorias?', 'respuesta': 'Periódicamente, las aguas del Mar de Cortés se vuelven muy ricas en nutrientes, que son base de la alimentación de peces de todos los tamaños. Además, algunas bahías e islas son zonas seguras para muchas especies de fauna.'},
            {'pregunta': '¿Qué especies marinas migratorias podemos encontrar en el Mar de Cortés?', 'respuesta': 'Entre la fauna migratoria del Mar de Cortés se encuentran: la ballena gris, tiburón ballena, tortuga golfina, tortuga verde del Pacífico, tortuga carey, calamar de Humboldt, cachalote y el chorlo nevado.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pargo rojo',
                'nombre_cientifico':'Lutjanus colorado',
                'conservacion':'Preocupación menor',
                'route':'pargo-rojo',
                'datos': [
                    'Los huevos fertilizados son pelágicos, esféricos, transparentes y con una sola gota de aceite.',
                    'La cantidad y diversidad de presas del pargo rojo  aumentan en el verano.',
                ],
                'generalidades': [
                    'Cuerpo alargado color rojo oscuro que gradualmente pasa a rosa en el vientre; aletas de color rojo oscuro.',
                    'Los adultos habitan en áreas de arrecifes costeros sobre fondos duros.',
                    'Su dieta consiste en invertebrados y peces.',
                ],
            },
            {
                'nombre_comun':'Pargo flamenco, lunarejo o p. de la Mancha',
                'nombre_display':'Pargo flamenco',
                'nombre_cientifico':'Lutjanus guttatus',
                'conservacion':'Preocupación menor',
                'route':'pargo-flamenco',
                'datos': [
                    'El más largo que se ha encontrado medía 80 cm y  el más pesado 1.3kg.',
                ],
                'generalidades': [
                    'Pez color rojo pálido o rosa a amarillento, plateado, con bandas de color verde dorado a café. Una mancha negra muy distintiva o grisácea por detrás.',
                    'Los adultos se encuentran sobre fondos duros en áreas de arrecifes costeros y los juveniles habitan estuarios y desembocaduras de ríos.',
                    'Se alimenta de invertebrados y peces.',
                ],
            },
            {
                'nombre_comun':'Pargo prieto, p. negro, p. dientón',
                'nombre_display':'Pargo prieto',
                'nombre_cientifico':'Lutjanus novemfasciatus',
                'conservacion':'Preocupación menor',
                'route':'pargo-prieto',
                'datos': [
                    'Es el pargo más grande del Pacífico.',
                ],
                'generalidades': [
                    'Pez de hocico puntiagudo, de color café oliva oscuro a rojo cobrizo en dorso y a los lados, barras de tono café cenizo y blanco plateado en el vientre.',
                    'Los adultos habitan en los arrecifes rocosos; los Juveniles, en estuarios con manglares y desembocaduras de ríos.',
                    'Se alimenta de grandes invertebrados y peces.',
                ],
            },
        ]
    },
    { 
        'id':'la-cueva', 'nombre':'LA CUEVA', 'habitat':'mar',
        'description':'Chicos y grandes podrán ver de cerca a los peces de la cueva al asomarse en la cápsula de este hábitat, y de paso sacar una divertida fotografía.',
        'preguntas': [
            {'pregunta': '¿Qué son las morenas?', 'respuesta': 'Son peces de cuerpo alargado y hábitos nocturnos, que durante el día prefieren descansar entre grietas y cuevas.'},
            {'pregunta': '¿Son peligrosas?', 'respuesta': 'Contrario a lo que se piensa son peces tranquilos, que prefieren permanecer en refugios, únicamente atacan si se sienten amenazadas, por eso es importante que respetemos sus espacios.'},
            {'pregunta': '¿De qué se alimentan las morenas?', 'respuesta': 'Su alimento favorito son los crustáceos y peces de menor tamaño que ellas, no tienen problema con alimentarse por la noche, ya que cuentan con un excelente olfato que les permite encontrar su comida.'},
        ],
        'especies': [
            {
                'nombre_comun':'Tieso tigre o anguila manchada',
                'nombre_display':'Tieso tigre',
                'nombre_cientifico':'Myrichthys tigrinus',
                'conservacion':'Preocupación menor',
                'route':'tieso-tigre',
                'datos': [
                    'Tiene cuatro narinas, las dos primeras, externas, son en forma de tubo y le son muy útiles para oler a sus presas.',
                    'Puede llegar a medir 74 cm.',
                ],
                'generalidades': [
                    'Pez de cuerpo cilíndrico y alargado de color crema con manchas negras ovaladas. Garganta y barilla manchadas. Su cola mide más de la mitad de su cuerpo. No tiene escamas.',
                    'Come camarones, cangrejos, gusanos, peces, también moluscos como pulpos y calamares.',
                    'Vive en fondos arenosos y lodosos, es común verla buscando presas bajo rocas o en grietas.',
                ],
            },
            {
                'nombre_comun':'Morena verde',
                'nombre_cientifico':'Gymnothorax castaneus',
                'conservacion':'Preocupación menor',
                'route':'morena-verde',
                'datos': [
                    'Su vista no es muy buena pero tiene muy buen sentido del olfato que le ayuda a ubicar y emboscar a sus presas.',
                    'Está cubierta de moco amarillo y espeso que la protege de las rocas entre las que vive.',
                ],
                'generalidades': [
                    'Es un pez de cuerpo alargado que se estrecha hacia una cola redondeada. Su color puede ser de verde a café. Tiene  cabeza grande y ojos pequeños.',
                    'Sus aletas anales y dorsales están cubiertas de piel y están fusionadas con la cola, lo que le da apariencia de listón o serpiente. Pueden llegar a medir metro y medio.',
                    'Come peces y crustáceos.',
                ],
            },
            {
                'nombre_comun':'Morena enmascarada o mapache',
                'nombre_display':'Morena enmascarada',
                'nombre_cientifico':'Gymnothorax panamensis',
                'conservacion':'Least concern',
                'route':'morena-enmascarada',
                'datos': [
                    'Abre y cierra constantemente la boca para poder respirar.',
                    'La forma de su cuerpo y la falta de aletas y escamasles permiten moverse rápidamente por las grietas rocosas.',
                    'Las morenas tienen 400% más probabilidades de supervivencia que el resto de los peces porque viven ocultas entre las rocas.',
                ],
                'generalidades': [
                    'Cabeza grande, hocico corto, dientes puntiagudos. Es de color café o café-amarillento en la cabeza. Sus ojos están  rodeados por un amplio anillo café oscuro y los poros en las mandíbulas rodeados de blanco.',
                    'Habita en fondos rocosos, ricos en grietas y hábitats de arrecifes.',
                    'Se alimenta de peces y crustáceos como cangrejos, langostas y camarones.',
                ],
            },
        ]
     },
    {
        'id':'salon-de-las-medusas', 'nombre':'SALÓN DE LAS MEDUSAS', 'habitat':'mar',
        'description':'Ven e inspirarte con el bello nado de las medusas, en una atmósfera mágica que hemos creado para ellas y para ti.',
        'preguntas': [
            {'pregunta': '¿Qué es una medusa?', 'respuesta': 'Son animales invertebrados (que no tienen huesos), que pertenecen al grupo de los cnidarios como los corales y las anémonas. Son conocidas por sus abundantes tentáculos, que usan para defenderse y capturar presas.'},
            {'pregunta': '¿Cómo se mueven?', 'respuesta': 'El característico nado de las medusas se debe a su capacidad de tensar su cuerpo para impulsarse en el agua, también suelen moverse aprovechando las corrientes marinas.'},
            {'pregunta': '¿Desde cuándo existen las medusas?', 'respuesta': 'Estos asombrosos animales existen desde hace más de 600 millones de años, mucho antes que los dinosaurios, por lo que son conocidos como fósiles vivientes.'},
        ],
        'especies': [
            {
                'nombre_comun':'Medusa luna',
                'nombre_cientifico':'Aurelia aurita',
                'conservacion':'No evaluado',
                'route':'medusa-luna',
                'datos': [
                    'Sus primeros años de vida son pólipos en suelos y rocas y luego se convierten en medusas móviles.',
                    'Puede ser una especie invasora cuando se reproduce exageradamente debido a la temperatura o la contaminación.',
                    'A partir de ellas se desarrolló una proteína fluorescente que se usa en la investigación biotecnológica.',
                ],
                'generalidades': [
                    'Es un invertebrado marino que en su etapa de medusa de nado libre tiene forma de campana y tentáculos, en los cuales suelen tener células urticantes.',
                    'Se alimenta de plancton, moluscos, huevos de pescado, peces pequeños y medusas más chicas.',
                ],
            },
            {
                'nombre_comun':'Medusa bola de cañón',
                'nombre_display':'Medusa bola/de cañón',
                'nombre_cientifico':'Stomolophus meleagris',
                'conservacion':'No evaluado',
                'route':'medusa-bola-canon',
                'datos': [
                    'Posee órganos sensores de luz (rhopalia y estatocistos) que le permite saber si va a la superficie o si va hacia lo profundo.',
                    'Cuando se siente amenazada secreta un moco tóxico que daña a los peces pequeños y ahuyenta a depredadores.',
                    'Se le pesca mucho en el norte de Sonora.',
                ],
                'generalidades': [
                    'Esta medusa es de color azulado o gris en aguas del Pacífico. Tiene 16 brazos orales blancos cortos y ramificados.',
                    'Se alimenta de larvas de peces, crustáceos,  moluscos y huevos de peces.',
                ],
            },
            {
                'nombre_comun':'Medusa de rayas moradas',
                'nombre_display':'Medusa de rayas/moradas',
                'nombre_cientifico':'Chrysaora colorata',
                'conservacion':'No evaluado',
                'route':'medusa-rayas-moradas',
                'datos': [
                    'Los juveniles de cangrejo de género Cáncer se asocian con esta medusa pues se alimentan de los parásitos que la dañan, a veces incluso viven dentro de ella.',
                    'La campana pulsa para moverse en distancias cortas, pero para ir más lejos, la medusa aprovecha las corrientes marinas.',
                ],
                'generalidades': [
                    'Cuando es adulta es de color blanco plateado con bandas de púrpura intenso. Las medusas jóvenes son de color rosa con tentáculos de color rojo oscuro. Todas tienen cuatro brazos orales en espiral.',
                    'Puede llegar a medir un metro de diámetro.',
                    'Se alimenta de zooplancton.',
                ],
            },
        ]
    },
    {
        'id':'cardumen', 'nombre':'CARDÚMEN', 'habitat':'mar',
        'description':'No te pierdas la sensación de tener sobre la cabeza un remolino de peces, un vórtice de vida.',
        'preguntas': [
            {'pregunta': '¿Qué es un cardumen?', 'respuesta': 'Conocemos como cardumen a los grupos de peces que nadan y se mueven juntos.'},
            {'pregunta': '¿Por qué se forman los cardúmenes?', 'respuesta': 'El agruparse así les trae algunos beneficios, por ejemplo: defenderse de depredadores, facilidad de obtener alimento y encontrar pareja.'},
            {'pregunta': '¿Cómo se guían los peces dentro del cardumen?', 'respuesta': 'Además de apoyarse de su visión, los peces cuentan con un órgano de sentido llamado línea lateral, que les permite detectar los cambios de presión, vibraciones y movimientos a su alrededor cuando algún pez cambia de dirección.'},
        ],
        'especies': [
            {
                'nombre_comun':'Sardina de California',
                'nombre_display':'Sardina/de California',
                'nombre_cientifico':'Sardinops sagax',
                'conservacion':'Preocupación menor',
                'route':'sardina-california',
                'datos': [
                    'Forman grandes cardúmenes de cientos de miles de millones de individuos. El movimiento rápido y coordinado de estos grupos son una adaptación para escapar de sus depredadores.',
                    'La sardina es migratoria y puede viajar distancias de 1000km en un año entre sus hábitats de alimentación y desove.',
                    'Son un recurso pesquero muy importante en México.',
                ],
                'generalidades': [
                    'La sardina de california Tiene un cuerpo cilíndrico y delgado de unos 20 cm de largo. Tiene el dorso verde azul y los lados blancos con 1-3 series de manchas oscuras.',
                    'Se alimenta de plancton.',
                ],
            },
        ]
    },

    {
        'id':'la-ventana', 'nombre':'LA VENTANA', 'habitat':'mar',
        'description':'Echa un último vistazo al gran hábitat oceánico a través de nuestra ventana y pesca algunas ideas de cómo podemos ayudar a conservar nuestros océanos.',
        'preguntas': [
            {'pregunta': '¿Qué importancia tiene el Mar de Cortés?', 'respuesta': 'El Mar de Cortés o Golfo de California es una zona que, por su clima y disponibilidad de recursos se ha convertido en el lugar preferido de muchos animales para alimentarse y reproducirse. El explorador Jacques Cousteau lo nombró "el acuario del mundo".'},
            {'pregunta': '¿Por qué se busca la conservación de nuestros océanos?', 'respuesta': 'Muchos programas de conservación se han enfocado en los océanos, porque estos sitios son nuestra principal fuente de oxígeno, ayudan a regular el clima y nos proveen de alimento.'},
            {'pregunta': '¿Qué podemos hacer desde casa para cuidar nuestros mares?', 'respuesta': 'Todos podemos ayudar a conservar estos importantes lugares, con acciones como reducir el uso de plásticos, disminuir el uso de electricidad y evitar dejar basura cuando visitamos la playa. Dentro del Gran Acuario podrás conocer más sobre cómo podemos trabajar juntos para tener un océano más sano y lleno de vida.'},
        ],
        'especies': [
            {
                'nombre_comun':'Tiburón martillo, cornuda común o cachona, morfillo',
                'nombre_display':'Tiburón martillo',
                'nombre_cientifico':'Sphyrna lewini',
                'conservacion':'En peligro crítico de extinción',
                'route':'tiburon-martillo',
                'datos': [
                    'Ha sido filmado con una cámara con carnada a 512m de profundidad.',
                    'Forma enormes escuelas de pequeños individuos migratorios se mueven en el verano en ciertas áreas. También existen poblaciones de residentes permanentes.',
                ],
                'generalidades': [
                    'Tiburón de cuerpo grande y moderadamente delgado; cabeza ancha con su característico "martillo". Es de color gris claro o marrón grisáceo por encima, sombreado a blanco por debajo.',
                    'Se alimenta principalmente  de peces, calamares y pulpos, también come langostas, camarones, cangrejos, así como otros tiburones y rayas.',
                ],
            },
            {
                'nombre_comun':'Pez gallo',
                'nombre_cientifico':'Nematistius pectoralis',
                'conservacion':'No evaluado',
                'route':'pez-gallo',
                'datos': [
                    'Se le busca mucho en pesca deportiva. El más grande qu han capturado medía 1.63m  y el más pesado casi 52kg.',
                ],
                'generalidades': [
                    'Cuerpo alargado y cabeza puntiaguda. Primera aleta dorsal tiene 7 espinas muy largas que se pliegan en un surco cuando la aleta se baja. Es de color gris azulado con reflejos plateados y barras negras.',
                    'Vive en zonas costeras poco profundas, le gustan las costas arenosas y forman bancos de peces.',
                    'Come peces pequeños.',
                ],
            },
            {
                'nombre_comun':'Pámpano común, plateado o toro',
                'nombre_display':'Pámpano común',
                'nombre_cientifico':'Trachinotus kennedyi',
                'conservacion':'Preocupación menor',
                'route':'pampano-comun',
                'datos': [
                    'Se ha capturado cerca de la desembocadura de ríos y fondos arenosos.',
                    'A pesar de que fue descito desde finales de 1800, se sabe muy poco sobre él.',
                    'Trachinotus significa espalda rugosa.',
                ],
                'generalidades': [
                    'Cuerpo corto, boca pequeña, nuca prominente; parte superior de la cabeza y cuerpo oscuro, a veces dorado o ligeramente bronceado, vientre plateado. Tiene una gran mancha negra en la aleta pectoral.',
                    'Habita en aguas pocas poco profundas y los juveniles en estuarios.',
                    'Se alimenta de moluscos, crustáceos y peces pequeños.',
                ],
            },
        ]
    },
];