import { Navbar, Sidebar } from "../components/Navigation"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import entradas from "./../styles/entradas.module.css"
import cx from "classnames"
import Footer from "../components/Footer"
import { Accordion } from "react-accessible-accordion"
import { AccordionItemEntradas, AccordionItemEntradasND, AccordionItemEspeciales, AccordionItemEventos } from "../components/AccordionItem"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);


export default function Entradas() {
    const tiburon = useRef(null);
    const timeline_tiburon = useRef();
    useEffect(() => {
        timeline_tiburon.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.eventos_tiburon',
                //markers: true,
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_tiburon.current.to(tiburon.current, {x: 120, duration: 2,});
    });

    return (
        <>
            <Navbar/>
            <Sidebar/>
            <Header routeName="entradas" altName="Niño mirando pecera" headerTitle="ENTRADAS" titleClasses="default"/>

            {/* Entradas */}
            <div className={ entradas.entradas + " section" }>
                <div className={ entradas.entradas_content + ' content' }>
                    <div className={ entradas.entradas_des }>
                        <div className={ cx(entradas.entradas_des__t1, entradas.title) + ' title' }>PUEDES COMPRAR TUS ENTRADAS ONLINE, SIN FILAS Y CON PUERTA DE ACCESO ESPECIAL O DIRECTAMENTE EN LA TAQUILLA</div>
                        <div className={ cx(entradas.entradas_des__t2) + ' title' }>Nuestro horario es de 10:00am a 6:00pm</div>
                        <div className={ cx(entradas.entradas_des__t2) + ' title' }>Cierre de taquillas a las 4:30pm</div>
                    </div>
                    <div className={ entradas.entradas_opt }>
                        <Accordion className={ entradas.accordion_entradas }>
                            <AccordionItemEntradas title="Adulto" price="380" des="Tendrás acceso a conocer los maravillosos 26 hábitats que se encuentran en nuestro edificio, además de lo que ofrece la zona alrededor de él. Así mismo tendrás la oportunidad de asistir a la Presentación de aves en el auditorio al aire libre. Acceso general 12 años en adelante."/>
                            <AccordionItemEntradas title="Adulto local" price="290" des="Tendrás acceso a conocer los maravillosos 26 hábitats que se encuentran en nuestro edificio, además de lo que ofrece la zona alrededor de él. Así mismo tendrás la oportunidad de asistir a la Presentación de aves en el auditorio al aire libre. El adulto deberá presentar credencial del INE vigente con domicilio en Mazatlán."/>
                            <AccordionItemEntradas title="Niño" price="280" des="Desde los 4 años hasta los 12 años. Menores de 4 años entrada gratuita presentando documento que acredite edad."/>
                            <AccordionItemEntradas title="Niño local" price="240" des="De 4 a 12 años. Menores de 4 años entrada gratuita presentando documento que acredite edad. El adulto que lo acompañe deberá presentar credencial del INE vigente con domicilio en Mazatlán."/>
                            <AccordionItemEntradas title="Mayores 65" price="270" des="Mayores de 65 años, pensionistas y/o jubilados. Presentar obligatoriamente documento que lo acredite."/>
                            <AccordionItemEntradasND title="Discapacidad" price="0"/>
                            <AccordionItemEntradas title="Grupos" price="" des="20 personas o más. Reservar en: " link="ventasgrupos@granacuario.com"/>
                        </Accordion>
                        <a href="https://tickets.granacuario.com/mx" className={ entradas.entradas_des__btn }>Compra entradas</a>
                        {/*
                        <div className={ entradas.donar }>
                            <a href="/" className={entradas.donar_btn }>Donar</a>
                            <div className={entradas.donar_des }>Dona $ 5.00 por boleto<br/>para el Centro de Investigación</div>
                        </div>
                        */}
                    </div>
                </div>
            </div>

            {/* Membresías */}
            <div className={ entradas.especiales + " section" }>
                <div className={ entradas.especiales_content + ' content'}>
                    <div className={ entradas.especiales_des }>
                        <div className={ cx(entradas.title, entradas.especiales_des__title, "title") }>MEMBRESÍAS</div>
                    </div>
                    <div className={ entradas.especiales_opt }>
                        <Accordion>
                            <AccordionItemEspeciales title="Pase anual" price="900" des="El pase individual te permitirá ingresar al Gran Acuario Mazatlán Mar de Cortés el número de veces que desees durante 365 días del año."/>
                            {/*<AccordionItemEspeciales title="Premium anual" price="" des="Disfruta de los beneficios de tu pase anual y beneficios adicionales, como un 10% de descuento en compras y consumo en el acuario. En tu primera visita disfruta del recorrido calypso (más información en actividades) y de la oportunidad de alimentar a las especies de bahía de rayas. Quienes adquieran la membresía anual antes de la apertura podrán participar en un recorrido especialmente diseñado para ellos."/>*/}
                            <AccordionItemEspeciales title="Pase anual familiar" price="2,500" des="Beneficios del pase anual para un máximo de 4 personas."/>
                            {/*<AccordionItemEspeciales title="Premium anual familiar" price="" des="Beneficios del premium anual para un máximo de 4 personas."/>*/}
                        </Accordion>
                        {/*<a href="https://tickets.granacuario.com/mx/1083-pase-anual-y-membresia" className={ entradas.entradas_des__btn }>Compra membresías</a>*/}
                        <a href="https://tickets.granacuario.com/mx" className={ entradas.entradas_des__btn }>Comprar membresías</a>
                    </div>
                </div>
            </div>

            {/* Eventos
            <div className={ entradas.eventos + ' section'}>
                <div className={ entradas.eventos_content + ' content'}>
                    <div className={ entradas.eventos_des }>
                        <div className={ entradas.title + " title" }>EXPERIENCIAS PREMIUM</div>
                        <div className={ entradas.des }>Todas las experiencias premium incluyen entrada general al Gran Acuario.</div>
                    </div>
                    <div className={ entradas.eventos_opt }>
                        <Accordion preExpanded={['a']}>
                            <AccordionItemEventos uuid="a" title="Recorrido Calypso" price="550" des="Adéntrate a las zonas de acceso especial y aprende con nuestros expertos. En el recorrido Calypso conocerás el área de preparación de alimento, te impactarás con los equipos de soporte de vida y podrás observar la alimentación de los tiburones. A partir de 12 años."/>
                            <AccordionItemEventos uuid="b" title="Inmersión con tiburones gata" price="570" des="¡Anímate a interactuar de cerca con tiburones! Si eres mayor de 8 años, podrás disfrutar de una experiencia única e interesante en el hábitat de los tiburones gata."/>
                            <AccordionItemEventos uuid="c" title="Nado con tiburones" price="2,890" des="Si eres mayor de 18 años y cuentas con certificación de buceo vigente, es tu oportunidad de disfrutar del nado con tiburones. Sumérgete con estos maravillosos habitantes del mar de Cortés y acompáñalos en su majestuoso nado en el hábitat oceánico."/>
                            <AccordionItemEventos uuid="d" title="Alimentación de rayas" price="470" des="Vive la diversión de alimentar a las rayas y aprende datos curiosos sobre estos interesantes peces."/>
                            <AccordionItemEventos uuid="e" title="Alimentación de tiburones" price="570" des="Sube a lo más alto y admira la imponente belleza del corazón de nuestro acuario, donde podrás tener la experiencia de alimentar a los majestuosos tiburones."/>
                        </Accordion>
                        <a href="https://tickets.granacuario.com/mx/982-experiencias-premium" className={ entradas.entradas_des__btn }>Compra experiencias premium</a>
                        <div className={ entradas.entradas_des__btn }>Proximamente</div>
                    </div>
                </div>
                <img ref={ tiburon } className={ entradas.eventos_bg + ' eventos_tiburon' } src="/images/entradas/tiburones.png" srcSet="/images/entradas/tiburones.webp" alt="Tiburones"/>
                <img className={ entradas.eventos_nina } src="/images/entradas/nina.png" srcSet="/images/entradas/nina.webp" alt="Tiburones"/>
            </div>
            */}

            {/* Patrocinador
            <div className={ cx(entradas.patrocinador, 'section')}>
                <div className={ cx(entradas.patrocinador_content, 'content')}>
                    <div className={ entradas.patrocinador_des }>
                        <div className={ entradas.patrocinador_des__t1 }>¡Reciba todas las novedades, promociones y eventos especiales del Gran Acuario de Mazatlán en su correo electrónico!</div>
                        <div className={ entradas.patrocinador_des__link }>Suscríbete al boletín mensual</div>
                        <div className={ entradas.patrocinador_des__link }>Introduce tu correo electrónico</div>
                        <div className={ entradas.patrocinador_des__link }>Política de privacidad</div>
                        <div className={ entradas.patrocinador_des__link }>Política de cookies</div>
                    </div>
                    <div className={ entradas.patrocinador_img }>
                        <img src="/images/entradas/coca-cola_logo.png" srcSet="/images/entradas/coca-cola_logo.webp" alt="Coca Cola Logo"/>
                        <div className={ entradas.patrocinador_img__des }>SPONSOR OFICIAL</div>
                    </div>
                </div>
                <Regresar des="Inicio" link="/"/>
            </div>
            */}

            <Footer/>
        </>
    )
}